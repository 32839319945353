import React, { useEffect, useState } from 'react';
import './Welcome.css';
import { ReactComponent as IconSvg } from '../../svg/PatiaIcon.svg'
import FullBodyIMG from '../FullBodyIMG/FullBodyIMG';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import horizontalCss from 'react-animated-slider/build/horizontal.css';
import { useTranslation } from "react-i18next";
import { changeLanguage } from 'i18next';

const Welcome = ({ navigate, getStatus, checkUserSession }) => {
    const [userInfo, setUserInfo] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const steps = 5;
    const {t} = useTranslation("common");

    const handleSlideChange = (index) => {
        setCurrentIndex(index);
    };

    useEffect(() => {
        // compruebo que el usuario esté logueado
        if (!getStatus().user){
            navigate('#login');
            return;
        }

        checkUserSession().then(userInfo => {
            console.log(userInfo);
            setUserInfo(userInfo);
            if(userInfo === null) {
                navigate('#login');
            }

            // Cambiar el idioma
            const userLang = userInfo.language;
            changeLanguage(userLang);
        });
    // eslint-disable-next-line
    }, [checkUserSession]);

    const NextButton = ({ onClick }) => (
        <button className="btn btn-primary effect effect-1" onClick={onClick}>
            {t('app.welcome.next')}
        </button>
    );

    const PreviousButton = ({ onClick }) => (
        <button className="btn btn-prev effect effect-3" onClick={onClick}>
            {t('app.welcome.prev')}
        </button>
    );

    const FinishButton = ({ onClick }) => (
        <button className="btn btn-terciary effect effect-1" onClick={onClick}>
            {t('app.welcome.finish')}
        </button>
    );

    return (
        <div className="patia-container-background">
            <FullBodyIMG />
            <IconSvg className="patia-icon" />
            <div className="patia-login-container">
                <div className="patia-login-form-container patia-welcome-container">
                    <div className='d-flex justify-content-between align-items-top'>
                        <div>
                            <h1 className='mb-0'>{t('app.welcome.greeting')} {userInfo?.usuario}</h1>
                            <p className='subtitle'>{t('app.welcome.subtitle')} </p>
                        </div>

                        {currentIndex < 4 && <button className="btn btn-terciary effect effect-1 mt-3" onClick={() => navigate('#informes')} >
                            {t('app.welcome.omit')}
                        </button>}
                    </div>

                    <Slider
                        classNames={`slider-wrapper ${horizontalCss}`}
                        direction="horizontal"
                        duration={1000}
                        onSlideChange={({ slideIndex }) => handleSlideChange(slideIndex)}
                        previousButton={currentIndex > 0 && <PreviousButton onClick={() => setCurrentIndex(currentIndex - 1)} />}
                        nextButton={currentIndex < steps - 1 ? <NextButton onClick={() => setCurrentIndex(currentIndex + 1)} /> : <FinishButton onClick={() => navigate('#informes')} />}
                    >
                        <div key={0} className="slider-content">
                            <div className="center inner">
                                <div className='patia-welcome-item position-relative'>
                                    <h3 className='patia-welcome-title'>{t('app.welcome.title1')}</h3>
                                    <ol>
                                        <li>{t('app.welcome.step1.first-step-1')}<strong>{t('app.welcome.step1.first-step-2')}</strong>{t('app.welcome.step1.first-step-3')}</li>
                                        <li>{t('app.welcome.step1.second-step-1')}<strong>{t('app.welcome.step1.second-step-2')}</strong>{t('app.welcome.step1.second-step-3')}</li>
                                    </ol>
                                    <img src='/1.svg' alt='Iconografía 1'/>
                                    <span className='top-0 start-0 position-absolute step-span'>{t('app.welcome.step1.step')}</span>
                                </div>
                            </div>
                        </div>

                        <div key={1}>
                            <div className="center">
                                <div className='patia-welcome-item position-relative'>
                                    <div className={`patia-welcome-item-content ${currentIndex === 2 ? 'overflow-hidden' : ''}`}>
                                        <h3 className='patia-welcome-title'>{t('app.welcome.title2')}</h3>
                                       <ol>
                                            <li>{t('app.welcome.step2.first-step-1')}<strong>{t('app.welcome.step2.first-step-2')}</strong>{t('app.welcome.step2.first-step-3')}</li>
                                            <li>{t('app.welcome.step2.second-step-1')}<strong>{t('app.welcome.step2.second-step-2')}</strong></li>
                                                <ul>
                                                    <li>{t('app.welcome.step2.second-step-2-option1')}</li>
                                                    <li>{t('app.welcome.step2.second-step-2-option2')}</li>

                                                    <ol>
                                                        <li><strong>{t('app.welcome.step2.second-step-2-column1')}</strong>{t('app.welcome.step2.second-step-2-column1-text')}</li>
                                                        <li><strong>{t('app.welcome.step2.second-step-2-column2')}</strong>{t('app.welcome.step2.second-step-2-column2-text')}</li>
                                                        <li><strong>{t('app.welcome.step2.second-step-2-column3')}</strong>{t('app.welcome.step2.second-step-2-column3-text')}</li>
                                                        <li><strong>{t('app.welcome.step2.second-step-2-column4')}</strong>{t('app.welcome.step2.second-step-2-column4-text')}</li>
                                                    </ol>

                                                    {currentIndex === 1 &&  <div className="table-box loaded mt-4 mb-4">
                                                        <table className="table table-bordered dashboard-table">
                                                            <thead>
                                                                <tr className='table-secondary table-borderless border-white'>
                                                                    <th>{t('app.welcome.step2.second-step-2-column1')}</th>
                                                                    <th>{t('app.welcome.step2.second-step-2-column2')}</th>
                                                                    <th>{t('app.welcome.step2.second-step-2-column3')}</th>
                                                                    <th>{t('app.welcome.step2.second-step-2-column4')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>XXXXXXXXX1</td>
                                                                    <td>SLC16A11</td>
                                                                    <td>rs75493593</td>
                                                                    <td>GT</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>XXXXXXXXX1</td>
                                                                    <td>INS-IGF2</td>
                                                                    <td>rs149483638</td>
                                                                    <td>GT</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>XXXXXXXXX1</td>
                                                                    <td>HNF1A</td>
                                                                    <td>rs483353044</td>
                                                                    <td>GT</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div> }
                                                </ul>
                                                <li>
                                                    <strong>{t('app.welcome.step2.patientDataBold')}</strong>: {t('app.welcome.step2.patientData')}
                                                    </li>
                                                    <li>{t('app.welcome.step2.selectFiles')}</li>
                                                    <ul>
                                                    <li>{t('app.welcome.step2.fileFormat')}</li>
                                                    <li>{t('app.welcome.step2.fileColumns')}</li>
                                                    <ol>
                                                    <li>
                                                    <strong>{t('app.welcome.step2.sampleIdBold')}</strong>: {t('app.welcome.step2.sampleId')}
                                                    </li>
                                                    <li>
                                                    <strong>{t('app.welcome.step2.patientNameBold')}</strong>: {t('app.welcome.step2.patientName')}
                                                    </li>
                                                    <li>
                                                    <strong>{t('app.welcome.step2.sex')}</strong>
                                                    </li>
                                                    <li>
                                                    <strong>{t('app.welcome.step2.dateOfBirthBold')}</strong>: {t('app.welcome.step2.dateOfBirth')}
                                                    </li>
                                                    <li>
                                                    <strong>{t('app.welcome.step2.weightBold')}</strong>: {t('app.welcome.step2.weight')}
                                                    </li>
                                                    <li>
                                                    <strong>{t('app.welcome.step2.heightBold')}</strong>: {t('app.welcome.step2.height')}
                                                    </li>
                                                    <li>
                                                    <strong>{t('app.welcome.step2.patientHistoryBold')}</strong>: {t('app.welcome.step2.patientHistory')}
                                                    </li>
                                                    <ul>
                                                    <li>{t('app.welcome.step2.patientHistoryNo')}</li>
                                                    <li>{t('app.welcome.step2.patientHistoryYes')}</li>
                                                    </ul>
                                                    <li>
                                                    <strong>{t('app.welcome.step2.diabetesParentsBold')}</strong>: {t('app.welcome.step2.diabetesParents')}
                                                    </li>
                                                    <ul>
                                                    <li>{t('app.welcome.step2.diabetesParentsNo')}</li>
                                                    <li>{t('app.welcome.step2.diabetesParentsOne')}</li>
                                                    <li>{t('app.welcome.step2.diabetesParentsBoth')}</li>
                                                    </ul>
                                                    <li>
                                                    <strong>{t('app.welcome.step2.collectionDateBold')}</strong>: {t('app.welcome.step2.collectionDate')}
                                                    </li>
                                                    <li>
                                                    <strong>{t('app.welcome.step2.receivedDateBold')}</strong>: {t('app.welcome.step2.receivedDate')}
                                                    </li>
                                                    <li>
                                                    <strong>{t('app.welcome.step2.practitionerNameBold')}</strong>: {t('app.welcome.step2.practitionerName')}
                                                    </li>
                                                    <li>
                                                    <strong>{t('app.welcome.step2.clientIdBold')}</strong>: {t('app.welcome.step2.clientId')}
                                                    </li>
                                                    <li>
                                                    <strong>{t('app.welcome.step2.clientAddressBold')}</strong>: {t('app.welcome.step2.clientAddress')}
                                                    </li>
                                                    <li>
                                                    <strong>{t('app.welcome.step2.observationsBold')}</strong>: {t('app.welcome.step2.observations')}
                                                    </li>
                                                </ol>
                                                </ul>
                                                <p>{t('app.welcome.step2.note')}</p>
                                                <ul>
                                                <li>{t('app.welcome.step2.decimalNote')}</li>
                                                <li>
                                                    <strong>{t('app.welcome.step2.unitsNoteBold')}</strong>: {t('app.welcome.step2.unitsNote')}
                                                </li>
                                                </ul>
                                            </ol>
                                    </div>
                                    <img src='/2.svg' alt='Iconografía 2'/>
                                    <span className='top-0 start-0 position-absolute step-span'>{t('app.welcome.step2.step')}</span>
                                </div>
                            </div>
                        </div>

                        <div key={2}>
                            <div className="center">
                                <div className='patia-welcome-item position-relative'>
                                    <h3 className='patia-welcome-title'>{t('app.welcome.title3')}</h3>
                                    <ol>
                                        <li>
                                        {t('app.welcome.step3.verifyFiles1')} <strong>{t('app.welcome.step3.filesBold')}</strong> {t('app.welcome.step3.verifyFiles2')} <strong>{t('app.welcome.step3.listBold')}</strong>.
                                        </li>
                                        <li>
                                        {t('app.welcome.step3.ensureInfo1')} <strong>{t('app.welcome.step3.fileInfoBold')}</strong> {t('app.welcome.step3.ensureInfo2')} <strong>{t('app.welcome.step3.correctBold')}</strong>.
                                        </li>
                                    </ol>
                                    <img src='/3.svg' alt='Iconografía 3'/>
                                    <span className='top-0 start-0 position-absolute step-span'>{t('app.welcome.step3.step')}</span>
                                </div>
                            </div>
                        </div>

                        <div key={3}>
                            <div className="center">
                                <div className='patia-welcome-item position-relative'>
                                    <h3 className='patia-welcome-title'>{t('app.welcome.title4')}</h3>
                                    <ol>
                                        <li>
                                        {t('app.welcome.step4.clickUploadButton1')} <strong>{t('app.welcome.step4.uploadButtonBold')}</strong>.
                                        </li>
                                        <li>{t('app.welcome.step4.waitForUpload')}</li>
                                    </ol>
                                    <img src='/4.svg' alt='Iconografía 4'/>
                                    <span className='top-0 start-0 position-absolute step-span'>{t('app.welcome.step4.step')}</span>
                                </div>
                            </div>
                        </div>

                        <div key={4}>
                            <div className="center">
                                <div className='patia-welcome-item position-relative d-flex flex-column justify-content-between'>
                                    <h3 className='patia-welcome-title'>{t('app.welcome.title5')}</h3>
                                    <ol>
                                        <li>
                                        {t('app.welcome.step5.onceUploaded')} <strong>{t('app.welcome.step5.reportListBold')}</strong> {t('app.welcome.step5.inTheMenu')}.
                                        </li>
                                        <li>
                                        {t('app.welcome.step5.accessProcessedBatch1')} <strong>{t('app.welcome.step5.processedBatchBold')}</strong>.
                                        </li>
                                        <li>
                                        {t('app.welcome.step5.clickGroup')} <strong>{t('app.welcome.step5.groupBold')}</strong> {t('app.welcome.step5.accessSamples')} <strong>{t('app.welcome.step5.processedSamplesBold')}</strong>.
                                        </li>
                                    </ol>
                                    <button className='btn btn-primary effect effect-2 mt-3 upload-report' onClick={() => navigate('#uploadreport?id=' + userInfo.usuario)}>{t('app.welcome.step5.upload')} </button>
                                    <img src='/5.svg' alt='Iconografía 5'/>
                                    <span className='top-0 start-0 position-absolute step-span'>{t('app.welcome.step5.step')}</span>
                                </div>
                            </div>
                        </div>

                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Welcome;