import React, { useState, useEffect } from 'react';
import ApiService from '../../service/ApiService.js';
import './SendFiles.css';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {useTranslation} from "react-i18next";
import { use } from 'i18next';

function SendFiles({ navigate, getStatus, id, institutionId }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [percProgress, setPercProgress] = useState(0);
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState(null);
  const apiService = new ApiService();
  const {t} = useTranslation("common");

  useEffect(() => {
    console.log('SendFiles institutionId: ' + institutionId);
    console.log('navigate: ' + navigate);
    console.log('getStatus: ' + getStatus);
    console.log('id: ' + id);
  }, [institutionId, navigate, getStatus, id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.target);
    formData.append('institutionId', institutionId);

    // Control para evitar que los setTimeout continúen si la respuesta ya ha llegado
    let requestCompleted = false;

    const setProgressWithCheck = (progress, delay) => {
        setTimeout(() => {
            if (!requestCompleted) {
                setPercProgress(progress);
            }
        }, delay);
    };

    setPercProgress(10);
    setProgressWithCheck(20, 600);
    setProgressWithCheck(30, 1100);
    setProgressWithCheck(40, 1500);
    setProgressWithCheck(50, 2000);
    setProgressWithCheck(60, 2400);
    setProgressWithCheck(70, 3000);
    setProgressWithCheck(80, 3400);

    apiService.post('upload.php', formData)
      .then(async response => {
        console.log(response);
        requestCompleted = true;
        if (!response) {
          setError(true);
          setErrorData("Operación no autorizada.");
          throw new Error('Not authorized');
        }
        if (response.success === false) {
          setError(true);
          setErrorData(response.data);
        }
          
        // Progresar hasta el 100% después de recibir la respuesta
        setTimeout(() => {
          setPercProgress(85);
        }, 500);
        setTimeout(() => {
          setPercProgress(95);
        }, 900);
        setTimeout(() => {
          setPercProgress(100);
        }, 1400);

        setTimeout(() => {
          setLoading(false);
          setData(response);
          // recargo la pagina para que se actualice la tabla
          if (!error && data) {
            navigate('#informes');
          }
        }, 1700);
      })
      .catch(error => {
        console.error('Error fetching data', error);
        setError(true);
        setErrorData("Ha ocurrido un error en la subida de los informes.");
        setData({ error: 'Error al cargar los datos' });
      })
  };


  return (
    <div className='mb-3'>
      <h4>{t('app.sendfiles.title')}</h4>
      <p className='fw-semibold'>{t('app.sendfiles.subtitle')}</p>
      {/* {!data && !loading && ( */}
        <form className='row g-3 mt-3' onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="col-5 input-file-container d-flex flex-column justify-content-end">
            <input type="hidden" name="MAX_FILE_SIZE" value="3000000" />
            <label htmlFor='archivo_csv_genoma' className='form-label fw-semibold mb-0'>{t('app.sendfiles.genomeData')}</label>
            <input id='archivo_csv_genoma' type="file" className="form-control" name="archivo_csv_genoma" accept=".csv" required />
          </div>
          <div className="col-5 input-file-container d-flex flex-column justify-content-end">
            <label htmlFor='archivo_csv_med' className='form-label fw-semibold mb-0'>{t('app.sendfiles.patientData')}</label>
            <input id='archivo_csv_med' type="file" className="form-control" name="archivo_csv_med" accept=".csv" required />
            <input type="hidden" name="user" value={id} />
          </div>
          <div className='col-1'></div>
          <div className="col-1 sendfiles-endbtn d-flex align-items-end justify-content-end">
            <button type="submit" className='btn btn-primary effect effect-2'>{t('app.sendfiles.upload')}</button>
          </div>
        </form>
      {/* )} */}
      {loading && !error &&
        <div className='mt-5'>
          <div className='progressInfo'>
            <p className='mb-0'>{percProgress === 100 ? !error ? t('app.sendfiles.success') : t('app.sendfiles.error') : t('app.sendfiles.load')}</p>
            <span>{percProgress}%</span>
          </div>

          <div className='progress-container mt-0'>
            <ProgressBar now={percProgress} />
          </div>

          <div className='mt-3'>
            <p>{t('app.sendfiles.wait')}</p>
          </div>
        </div>
      }
      {error && <p className="upload-error mt-3">{errorData}</p>}
      {/* Navegar a informes cuando termine */}
      {data && !error && navigate('#informes')}
    </div>
  );
}

export default SendFiles;