import React from 'react';
import './FullBodyIMG.css';
import { ReactComponent as MoveIcon } from '../../svg/MoveIcon.svg';
import { ReactComponent as PatiaIcon } from '../../svg/PatiaIconSidebar.svg';

const FullBodyIMG = () => {
    return (
        <div className='FullBodyIMG'>
            <PatiaIcon/>
            <div className='mb-4'>
                <MoveIcon />
                <h5 className='mt-3'>
                    Gire la pantalla para una mejor experiencia
                </h5>
            </div>
        </div>
    );
};

export default FullBodyIMG;
