// Login.js
import React, { useState, useEffect } from 'react';
import ApiService from '../../service/ApiService';
import './Login.css';
import { ReactComponent as IconSvg } from '../../svg/PatiaIcon.svg'
import { ReactComponent as LogoSvg } from '../../svg/PatiaLogo.svg'
import { useTranslation } from "react-i18next";

function Login({ checkUserSession, getStatus, navigate }) {
  const {t} = useTranslation("common");
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [verify, setVerify] = useState(false);
  const [error, setError] = useState(false);
  const apiService = new ApiService();
  // eslint-disable-next-line
  const [isNewUser, setIsNewUser] = useState(false);

  useEffect(() => {
    if(!verify){
      checkUserSession().then(userInfo => {
        console.log('user',userInfo);
        if (userInfo) {
          const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');
          if (userInfo.admin) {
            navigate('#informes');
          }else{
            if (!hasVisitedBefore) {
              // Si no ha visitado antes, es un nuevo usuario
              setIsNewUser(true);
              localStorage.setItem('hasVisitedBefore', 'true');
              navigate('#welcome');
            }else {
              navigate('#informes');
            }
          }
        }
      });
      setVerify(true);
    }
  },[setVerify, verify, checkUserSession, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
  
    try {

      const body = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;
      const options = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        credentials: 'include',
        mode: 'no-cors',
      };
  
      apiService.post('login.php', body, options)
      .then(response => {
        if(response.success === false) {
          setError(true);
          return;
        }

        // if (!response) throw new Error('Not authorized');
        // si el usuario es administrador lo redirijo al dashboard
        //console.log("login hecho",response);
        checkUserSession().then(userInfo => {
          console.log('user',userInfo);
          if (userInfo) {
            const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');
            if (userInfo.admin) {
              navigate('#informes');
            }else{
              if (!hasVisitedBefore) {
                // Si no ha visitado antes, es un nuevo usuario
                setIsNewUser(true);
                localStorage.setItem('hasVisitedBefore', 'true');
                navigate('#welcome');
              }else {
                navigate('#informes');
              }
            }
            // navigate('#2fa');
          }else{
            navigate('#login');
          }
        });
      })
      .catch(error => {
        console.error('Login error', error);
        setError(true);
      });
    } catch (error) {
      console.error("Login error", error);
      setError(true);
    }
  };

  return (
    <div className="patia-container-background">
      <IconSvg className="patia-icon" />
      <div className="patia-login-container">
        <div className="patia-login-background"> 
          <div className="patia-logo-container">
            <LogoSvg className="patia-logo" />
          </div>
        </div>
        <div className="patia-login-form-container">
          <p  className="patia-need-help"></p>
          {
            !getStatus().user ?
              <form className='patia-form patia-form-user' onSubmit={handleLogin}>
                <div className='patia-form-container'>
                  <h2 className='mb-4 patia-subheading'>{t('app.login')}</h2>
                  <div className='d-flex flex-column mb-3 patia-form-input'>
                    <input type="text" name="email" value={username} onChange={(e) => setUsername(e.target.value)} required minLength={1}/>
                    <label htmlFor='email'>{t('app.login.user')}</label>
                  </div>
                  <div className='d-flex flex-column patia-form-input'>
                    <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} required minLength={1} className='mb-1'/>
                    <label htmlFor='password'>{t('app.login.password')}</label>
                  </div>
                  {error && <p className="patia-error">{t('app.login.error')}</p>}
                  <a href='/#password-recovery' className="patia-password-recovery">{t('app.login.recover_pass')}</a>
                  <button type="submit" className='effect effect-1 btn-primary'>{t('app.login.send')}</button>
                  {/* <a href='/#register' className="patia-form-subtxt mt-3" onClick={() => navigate('#register')}>Si aún no estas registrado date de alta</a>
                  <a href='/#register' className="btn btn-terciary btn-subtxt effect effect-1" onClick={() => navigate('#register')}>Registro</a> */}
                </div>
              </form>
            :
            getStatus().user.admin ? navigate('#informes') : navigate('#welcome')
          }
        </div>
      </div>
    </div>
  );
}

export default Login;
