import React from 'react';
import './TableBox.css';

const TableBox = ({ data }) => {
  return (
    <table className='table table-bordered dashboard-tablebox table-secondary table-box'>   
            {Object.entries(data).map(([key, value]) => (
                <tr>
                    <th className='border-white table-secondary' key={key}>{key}</th>
                    <td key={key+"value"}>{value}</td>
                </tr>
            ))}
    </table>
  );
};

export default TableBox;