import React from 'react';
import './SideMenu.css';
import { ReactComponent as LogoTitle } from '../../svg/Title.svg';
import { ReactComponent as LogoIcon } from '../../svg/PatiaIconSidebar.svg';
import { ReactComponent as UserIcon } from '../../svg/UserIcon.svg';
import { ReactComponent as UserAdd } from '../../svg/UserAdd.svg';
import { ReactComponent as PlusIcon } from '../../svg/PlusIcon.svg';
import { ReactComponent as FaqIcon } from '../../svg/FaqIcon.svg';
import { ReactComponent as LogoutIcon } from '../../svg/LogoutIcon.svg';
import { ReactComponent as FileIcon } from '../../svg/fileIcon.svg';
import { ReactComponent as ArrowTopIcon } from '../../svg/arrowTopIcon.svg';
import { ReactComponent as AdminIcon } from '../../svg/AdminIcon.svg';
import { ReactComponent as PdfEdit } from '../../svg/PdfEdit.svg';
import { useEffect, useState } from 'react';

import ApiService from '../../service/ApiService';
import {useTranslation} from "react-i18next";

const SideMenu = ({ navigate, getStatus, userInformation }) => {
  const apiService = new ApiService();
  const [user, setUser] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [usersLeft, setUsersLeft] = useState(null);
  const {t} = useTranslation("common");

  useEffect(() => {
    setUser(getStatus().user);
    setUserInfo(userInformation);

    if(user.insti) {
      // Si el usuario es una institución, hacer un get users.php?catidad=true para saber cuantos usuarios puede crear
      apiService.get('users.php?cantidad=true').then(response => {
        console.log('usuarios restantes: ', response);
        setUsersLeft(response.SN);
      });
    }
  }, [getStatus, userInformation]);


  //funcion para hacer logout, hacer fetch a logout.php
  const handleLogout = async () => {
    apiService.get('logout.php').then(response => {
      if (!response) throw new Error('Not authorized');
      console.log('logout hecho', response);
      navigate('#login')
    });
  };

  //funcion para ir a la pagina de FAQs

  const handleClose = async () => {
    let sidemenubox = document.getElementById('sidemenubox');
    /*cambio el display a none para que se oculte el sidemenu*/
    sidemenubox.style.display = 'none';
    /* oculto el icono de cerrar */
    let sidemenuclose = document.getElementById('sidemenuclose');
    sidemenuclose.style.display = 'none';
  }

  const handleListMenu = () => {
    navigate('#informes')
  }

  return (
    <div id="sidemenubox" className="sidemenu-container col-3 col-lg-2">
        <div className="close-section">
            <span id="sidemenuclose" className="icon" onClick={handleClose}>
              &#8592;
            </span>
        </div>
        <div className="logo-section">
            <LogoIcon />
            <LogoTitle className='mt-1' />
        </div>
        {/* Datos de usuario */}
        {/* <div className="user-info d-flex align-items-center gx-5 mb-4">
            <div className='text-align-left'>
              <UserIcon/>
            </div>
            <div className='text-align-left'>
              <h6 className='fw-bold m-0 user-info-text'>{t('app.home.profile')}</h6>
            </div>
        </div> */}

        <div className="user-info d-flex align-items-center gx-5 mb-4" onClick={handleListMenu}>
            <div className='text-align-left'>
              <FileIcon/>
            </div>
            <div className='text-align-left'>
              <h6 className='fw-bold m-0 user-info-text'>{user?.admin ? t('app.home.listInstitutions') : user?.insti ? t('app.home.listUsers') :  t('app.home.listReports')}</h6>
            </div>
        </div>

        {user?.admin && <div className="user-info d-flex align-items-center gx-5 mb-4" onClick={() => navigate('#institution')}>
            <div className='text-align-left'>
              <PlusIcon/>
            </div>
            <div className='text-align-left'>
              <h6 className='fw-bold m-0 user-info-text'>{t('app.home.newInstitution')}</h6>
            </div>
        </div> }

        {user?.admin && <div className="user-info d-flex align-items-center gx-5 mb-4" onClick={() => navigate('#admin')}>
            <div className='text-align-left'>
              <AdminIcon/>
            </div>
            <div className='text-align-left'>
              <h6 className='fw-bold m-0 user-info-text'>{t('app.home.billing')}</h6>
            </div>
        </div> }

        {user && !user.admin && !user.insti && <div className="user-info d-flex align-items-center gx-5" onClick={() => navigate('#uploadreport?id=' + userInfo?.usuario + '&identificator=' + userInfo?.idInstitucion)}>
            <div className='text-align-left'>
              <ArrowTopIcon/>
            </div>
            <div className='text-align-left'>
              <h6 className='fw-bold m-0 user-info-text'>{t('app.home.uploadReport')}</h6>
            </div>
        </div>}

        {user.insti && usersLeft && <div className="user-info d-flex align-items-center gx-5 mb-4" onClick={() => navigate('#user')}>
            <div className='text-align-left'>
              <UserAdd/>
            </div>
            <div className='text-align-left'>
              <h6 className='fw-bold m-0 user-info-text'>{t('app.home.newUser')}</h6>
            </div>

        </div>}

        {user.insti && <div className="user-info d-flex align-items-center gx-5" onClick={() => navigate('#institution?id=' + user.id)}>
            <div className='text-align-left'>
              <PdfEdit/>
            </div>
            <div className='text-align-left'>
              <h6 className='fw-bold m-0 user-info-text'>{t('app.home.customPDF')}</h6>
            </div>

        </div>}

        {/* Botones */}
        <div className='sidemenu-btns'>
        <div className="user-info">
          <h6 className='mb-3 user-info-text' role='button' onClick={() => navigate('#welcome')}>
            <span className="icon">
              <FaqIcon />
            </span>
            {t('app.home.help')}
          </h6>
        </div>
        <div className="user-info d-flex align-items-center gx-5">
          <h6 onClick={handleLogout} role='button' className='user-info-text'>
            <span className="icon">
              <LogoutIcon />
            </span>
            {t('app.home.logout')}
          </h6>
        </div>
        </div>
    </div>
  );
};

export default SideMenu;
