
import React, { useState } from 'react';
import './Paginator.css';
import {useTranslation} from "react-i18next";

const Paginator = ({ count, onChangePage }) => {
  const {t} = useTranslation("common");
  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (page) => {
    setActivePage(page);
    onChangePage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    if (activePage > 1) {
      pageNumbers.push(
        <li key="prev" className="page-item-p">
          <span className="page-link-p" onClick={() => handlePageChange(activePage - 1)}>
            &#60; {t('app.reports.prev')}
          </span>
        </li>
      );
    }else{
      pageNumbers.push(
        <li key="prev" className="page-item-p">
          <span className="page-link-p">
          </span>
        </li>
      );
    }
    
    if(count > 1){
      pageNumbers.push(
        <span key="current" className={`page-item active`}>
          {activePage}/{Math.ceil(count)}
        </span>
      );
    }

    if (activePage < count) {
      pageNumbers.push(
        <li key="next" className="page-item-n">
          <span className="page-link-n" onClick={() => handlePageChange(activePage + 1)}>
          {t('app.reports.next')} &#62;
          </span>
        </li>
      );
    }else{
      pageNumbers.push(
        <li key="next" className="page-item-n">
          <span className="page-link-n">
          </span>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <nav className='mt-2 mb-1'>
      <ul className="pagination justify-content-center">
        {renderPageNumbers()}
      </ul>
    </nav>
  );
};

export default Paginator;
