import React, { useState, useCallback, useMemo } from 'react';
import ApiService from './ApiService';
import Landing from '../module/Landing/Landing';
import { changeLanguage } from './LanguageService';

function RouterService({ children }) {
  const [route, setRoute] = useState(window.location.pathname || '/loading');
  
  // useMemo para crear la instancia de ApiService solo una vez
  const apiService = useMemo(() => new ApiService(), []);
  const [routeVisits, setRouteVisits] = useState({}); // Almacena las visitas por ruta

  const [userData, setUser] = useState(null);

  // useCallback para setUserData
  const setUserData = useCallback((user) => {
    setUser(user);
  }, []);

  const navigate = useCallback((newRoute) => {
    newRoute = newRoute.replace('#', '/');
    window.history.pushState({}, '', newRoute);
    setRouteVisits(prev => ({...prev, [newRoute]: (prev[newRoute] || 0) + 1})); // Incrementa la visita a la ruta
    setRoute(newRoute);
  }, []);

  const getStatus = useCallback(() => {
    return {
      user: userData,
      route: route,
    };
  }, [userData, route]);

  const checkUserSession = useCallback(async () => {
    try {
      const userInfo = await apiService.get('verify.php');
      setUserData(userInfo);
      // cambio el idioma
      changeLanguage(userInfo.language || 'en');
      return userInfo;
    } catch (error) {
      console.error('User not logged in', error);
      return;
    }
  }, [apiService, setUserData]);

  // Modificado para asignar una key dinámica basada en las visitas a la ruta
  const CurrentComponent = children.find(child => child.key.startsWith(route.split('?')[0]));
  const currentKey = `${route.split('?')[0]}-${routeVisits[route.split('?')[0]] || 0}`;

  return CurrentComponent ? React.cloneElement(CurrentComponent, { navigate, getStatus, checkUserSession, key: currentKey }) : React.cloneElement(<Landing />, { navigate, getStatus, checkUserSession });
}

export default RouterService;