import React from 'react';
import './InfoBox.css';

const InfoBox = ({ title, number, subtitle }) => {
  return (
    <div className="info-box">
      <div className="info-box__text">{title}</div>
      <div className="info-box__number">{number}</div>
      <div className="info-box__text-secundary">{subtitle}</div>
    </div>
  );
};

export default InfoBox;
