import React, {useEffect, useState} from 'react'
import UserForm from '../UserForm/UserForm'
import SideMenu from '../SideMenu/SideMenu';
import SideMenuMobile from '../SideMenuMobile/SideMenuMobile';
import FullBodyIMG from '../FullBodyIMG/FullBodyIMG';

const UserPage = ({checkUserSession, navigate, getStatus}) => {

    const [userId, sertUserId] = useState(null);
    const [institutionId, setInstitutionId] = useState(null);

    useEffect(() => {
        // Verificar que el usuario esté logueado
        const userStatus = getStatus();
        if (!userStatus.user) {
            navigate('#login');
        }
        checkUserSession().then(userInfo => {
            console.log('user',userInfo);
            console.log("id de la institucion: " + userInfo.idInstitucion);
            setInstitutionId(userInfo.idInstitucion);
        }
        );

        // Función para obtener el parámetro 'id' de la URL
        const getIdFromUrl = () => {
            const queryParams = new URLSearchParams(window.location.search);
            return queryParams.get('id');
        };

        const id = getIdFromUrl();
        if (id) {
            sertUserId(id);
        }
    }, []); 
  return (
    <div className='container-fluid container-dashboard'>
        <FullBodyIMG />
        <div className='row'>
            <SideMenu navigate={navigate} getStatus={getStatus} />
            <SideMenuMobile/>
            <div className='col-11 col-lg-10 dashboard-box'>
                <div className='card mt-4 mb-4 pt-5 row border-white'>
                    <UserForm userId={userId} navigate={navigate} institutionId={institutionId}/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default UserPage