import React, {useEffect, useState} from 'react'
import InstitutionForm from '../InstitutionForm/InstitutionForm'
import SideMenu from '../SideMenu/SideMenu';
import SideMenuMobile from '../SideMenuMobile/SideMenuMobile';
import FullBodyIMG from '../FullBodyIMG/FullBodyIMG';

const InstitutionPage = ({navigate, getStatus, checkUserSession}) => {

    const [institutionId, setInstitutionId] = useState(null);

    useEffect(() => {
        // Verificar que el usuario esté logueado
        const userStatus = getStatus();
        if (!userStatus.user) {
            navigate('#login');
        }

        // Función para obtener el parámetro 'id' de la URL
        const getIdFromUrl = () => {
            const queryParams = new URLSearchParams(window.location.search);
            return queryParams.get('id');
        };

        const id = getIdFromUrl();
        if (id) {
            setInstitutionId(id);
        }

    }, [getStatus, navigate]); 
  return (
    <div className='container-fluid container-dashboard'>
        <FullBodyIMG />
        <div className='row'>
            <SideMenu navigate={navigate} getStatus={getStatus} />
            <SideMenuMobile/>
            <div className='col-11 col-lg-10 dashboard-box'>
                <div className='card mt-4 mb-4 pt-5 row border-white'>
                    <InstitutionForm institutionId={institutionId} navigate={navigate} checkUserSession={checkUserSession}/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default InstitutionPage