// languageService.js
import i18next from 'i18next';
import common_es from './../translations/es/common.json';
import common_en from './../translations/en/common.json';

const initI18n = () => {
  i18next.init({
    interpolation: { escapeValue: false },
    lng: 'en', // Valor por defecto
    resources: {
      es: {
        common: common_es,
      },
      en: {
        common: common_en,
      },
    },
  });

  // Detectar el idioma del navegador
  const userLang = navigator.language || navigator.userLanguage;
  if (userLang === 'es-ES') {
    i18next.changeLanguage('es');
  } else {
    i18next.changeLanguage('en');
  }
};

const changeLanguage = (language) => {
  i18next.changeLanguage(language);
};

export { initI18n, changeLanguage };