import React from 'react';
import Select from 'react-select';
import './Filters.css';

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultValue: '',
      inputName: 'emaila'
    };
  }

  componentDidMount() {
    this.updateInputName();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.title !== this.props.title) {
      this.updateInputName();
    }
  }

  updateInputName() {
    const { title } = this.props;
    console.log('title: ' + title);
    if(title) {
      if (title.includes('Usuarios') || title.includes('Users') || title.includes('Instituciones') || title.includes('Institutions')) {
        this.setState({ inputName: 'emaila' });
      } else if (title.includes('Subidas') || title.includes('Uploads')) {
        this.setState({ inputName: 'group' });
      } else {
        this.setState({ inputName: 'group' });
      }
    }
  }

  change = (e) => {
    this.setState({ defaultValue: e.target.value });
  }

  submit = (e) => {
    e.preventDefault();
    const input = e.target.elements[this.state.inputName];

    const simulatedEvent = {
      target: input
    };

    this.props.onChangeFilter(simulatedEvent);
  }

  render() {
    const {
      isAdmin,
      onCountryChange,
      onClientChange,
      onUploadDateChange,
      onDataTypeChange,
      options1,
      options2,
      title,
      subtitle,
      subtitleClass,
      active,
      t
    } = this.props;

    return (
      <div className='row mb-3 mt-5'>
        <div className='input-group mt-1 d-flex justify-content-between align-items-center'>
          {isAdmin ? onCountryChange ? (
            <>
              {title ? <h5 className='filter-title col-12'>{title}</h5> : null}
              {subtitle ? <h6 className={`filter-subtitle mt-4 col-12 ${subtitleClass}`}>{subtitle}</h6> : null}
              <Select onChange={onCountryChange} options={options1} />
              <Select onChange={onClientChange} options={options2} />
            </>
          ) : (
            <>
              {title ? <h5 className='filter-title col-12'>{title}</h5> : null}
              {subtitle ? <h6 className={`filter-subtitle col-12 mt-4 ${subtitleClass}`}>{subtitle}</h6> : null}
              <Select onChange={onUploadDateChange} options={options1} />
              <Select onChange={onDataTypeChange} options={options2} />
            </>
          ) : (
            <div className='col-9 row d-flex align-items-end'>
              {title ? <div className='d-flex justify-content-start align-items-center gap-4'><h5 className='filter-title'>{title}</h5> </div> : null}
              {subtitle ? <h6 className={`filter-subtitle mt-4 ${subtitleClass}`}>{subtitle}</h6> : null}
            </div>
          )}
          {active ? (
            <form onSubmit={this.submit} className='col-3'>
              <div className="input-group patia-search-input">
                <input 
                  placeholder={t('app.reports.search')}
                  className="form-control border-end-0 border filter-search" 
                  type="text" 
                  value={this.state.defaultValue} 
                  onChange={this.change} 
                  name={this.state.inputName}
                />
                <span className="input-group-append">
                  <button className="btn btn-outline-secondary bg-white border-start-0 border ms-n5 search-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                    </svg>
                  </button>
                </span>
              </div>
            </form>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Filters;
