import React from 'react';
import './App.css';
import Loading from '../module/Loading/Loading.js';
import Login from '../module/Login/Login.js';
import Register from '../module/Register/Register.js';
import SendFiles from '../module/SendFiles/SendFiles.js';
import RouterService from '../service/RouterService.js';
import Dashboard from '../module/Dashboard/Dashboard.js';
import Landing from '../module/Landing/Landing.js';
import Welcome from '../module/Welcome/Welcome.js';
import UploadReport from '../module/UploadReport/UploadReport.js';
import TwoFactor from '../module/TwoFactor/TwoFactor.js';
import PrivacyPolicy from '../module/PrivacyPolicy/PrivacyPolicy.js';
import TermsConditions from '../module/TermsConditions/TermsConditions.js';
import InstitutionPage from '../module/InstitutionPage/InstitutionPage.js';
import UserPage from '../module/UserPage/UserPage.js';
import '../bootstrap/dist/css/bootstrap-grid.css';
import AdminPage from '../module/AdminPage/AdminPage.js';
import CustomizePDFPage from '../module/CustomizePDFPage/CustomizePDFPage.js';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <RouterService>
          <Landing key="/landing"/>
          <Loading key="/loading"/>
          <Landing key="/"/>
          <Landing key=""/>
          <Login key="/login"/>
          <TwoFactor key="/2fa"/>
          <Register key="/register"/>
          <Dashboard key="/informes" />
          <SendFiles key="/sendfiles" />
          <Welcome key="/welcome" />
          <UploadReport key="/uploadreport" />
          <PrivacyPolicy key="/privacypolicy" />
          <TermsConditions key="/termsconditions" />
          <InstitutionPage key="/institution" />
          <UserPage key="/user" />
          <AdminPage key="/admin" />
          <CustomizePDFPage key="/customizepdf" />
        </RouterService>
      </header>
    </div>
  );
}

export default App;
