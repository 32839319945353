import React, { useState, useRef, useEffect } from 'react';
import ApiService from '../../service/ApiService';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SeePass } from '../../svg/eye.svg';
import { ReactComponent as HidePass } from '../../svg/not-eye.svg';
import './../InstitutionForm/InstitutionForm.css';

const UserForm = ({userId, navigate, institutionId}) => {
    const { t } = useTranslation('common');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [hidePass, setHidePass] = useState(true);
    const [hidePassRepeat, setHidePassRepeat] = useState(true);
    const form = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordRepeatRef = useRef();
    const languageRef = useRef();
    const apiService = new ApiService();

    useEffect(() => {
        if (userId) {
            const filter = {
                limit: 10,
                skip: 0,
                where: {
                    id: ["id", parseInt(userId), "="]
                },
                order: "id DESC"
            };
            const encodedFilter = encodeURIComponent(JSON.stringify(filter));
            const url = `users.php?filter=${encodedFilter}`;
            // Get institution data
            apiService.get(url)
                .then(response => {
                    console.log(response);
                    if (!response) {
                        setError(true);
                        setErrorMessage('Operación no autorizada.');
                    }
                    if (response.success === false) {
                        setError(true);
                        setErrorMessage("Error al obtener la institución");
                    }
                    // Set institution data
                    emailRef.current.value = response.data[0].emaila;
                    languageRef.current.value = response.data[0].language;

                    const labels = form.current.querySelectorAll('label');
                    labels.forEach(label => {
                        label.classList.add('active');
                    });
                })
                .catch(error => {
                    setError(true);
                    setErrorMessage('Ha ocurrido un error al obtener la institución, inténtalo más tarde.');
                    console.error(error);
                });
        }
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (passwordRef.current.value !== passwordRepeatRef.current.value) {
            setError(true);
            setErrorMessage('Las contraseñas no coinciden');
            setLoading(false);
            return;
        }

        // const formData = new FormData();
        // formData.append('emaila', emailRef.current.value);
        // formData.append('pasahitza', passwordRef.current.value);
        // formData.append('roll', 'user');
        // formData.append('idInstitucion', institutionId);
        // formData.append('activo', 1);
        // formData.append('id', userId);
        //send data in json format
        const formData = {
            emaila: emailRef.current.value,
            pasahitza: passwordRef.current.value,
            roll: 'user',
            idInstitucion: institutionId,
            activo: 1,
            id: userId,
            language: languageRef.current.value
        };

        const options = {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
        };
                
        if (userId) {
            // Update institution
            apiService.put(`users.php`, formData, options)
                .then(response => {
                    setError(false);
                    setLoading(false);
                    setSuccess(true);   

                    setTimeout(() => {
                        navigate('#informes');
                    }, 1000);
                })
                .catch(error => {
                    setError(true);
                    setErrorMessage(t('app.form.userEditError'));
                    console.error(error);
                    setLoading(false);
                });
        } else {
            apiService.post('users.php', formData, options)
            .then(response => {
                console.log(response);
                if (!response) {
                    setError(true);
                    setErrorMessage('Operación no autorizada.');
                } 
                if (response.success === false) {
                    setError(true);
                    setErrorMessage(t('app.form.userCreateError'));
                }
                setSuccess(true);
                setLoading(false);

                setTimeout(() => {
                    navigate('#informes');
                }, 1000);
            })
            .catch(error => {
                setError(true);
                setErrorMessage(t('app.form.userCreateError'));
                console.error(error);
                setLoading(false);
            });
        }
    };

    const handleFocusLabel = (e) => {
        const label = e.target.previousElementSibling;
        label.classList.add('active');
    }

    const handleBlurLabel = (e) => {
        const label = e.target.previousElementSibling;
        if (e.target.value === '') {
            label.classList.remove('active');
        }
    }

    const handlePass = (value) => {
        setHidePass(value);
        if (value) {
            passwordRef.current.type = 'password';
        } else {
            passwordRef.current.type = 'text';
        }
    }

    const handleRepeatPass = (value) => {
        setHidePassRepeat(value);
        if (value) {
            passwordRepeatRef.current.type = 'password';
        } else {
            passwordRepeatRef.current.type = 'text';
        }
    }

    return (
        <div>
            <h4 className='col-12 ms-2'>{userId ? t('app.form.editUser') : t('app.form.newUser')}</h4>
            <p className='col-12 fw-semibold ms-2'>{userId ?  t('app.form.editUserSubtitle') : t('app.form.newUserSubtitle')}</p>
            <form className='row landing-form px-0 mx-0 mt-4' ref={form} onSubmit={handleSubmit}>
                <div className='input-group col-6 mb-3'>
                    <label>{t('app.form.email')}</label>
                    <input onBlur={handleBlurLabel} onFocus={handleFocusLabel} ref={emailRef} type='email' className='form-control' placeholder='Introduzca su email' required />
                </div>
                <div className='input-group col-6 mb-3 relative'>
                    <label>{t('app.form.password')}</label>
                    <input onBlur={handleBlurLabel} onFocus={handleFocusLabel} ref={passwordRef} type='password' className='form-control' placeholder='Introduzca su contraseña' />
                    {hidePass ? <HidePass className='icon-pass' onClick={() => handlePass(false)} /> : <SeePass className='icon-pass' onClick={() => handlePass(true)} />}
                </div>
                <div className='input-group col-6 mb-3'>
                    <label>{t('app.form.passwordRepeat')}</label>
                    <input onBlur={handleBlurLabel} onFocus={handleFocusLabel} ref={passwordRepeatRef} type='password' className='form-control' placeholder='Repetir contraseña' />
                    {hidePassRepeat ? <HidePass className='icon-pass' onClick={() => handleRepeatPass(false)} /> : <SeePass className='icon-pass' onClick={() => handleRepeatPass(true)} />}
                </div>
                <div className='input-group col-6 mb-3'>
                    <select className='form-control' ref={languageRef}>
                        {!userId && <option value="" disabled selected>{t('app.form.language')}</option>}
                        <option value='es'>{t('app.form.es')}</option>
                        <option value='en'>{t('app.form.en')}</option>
                    </select>
                </div>
               
                <div className='input-group col-12 mt-2'>
                    <button className='btn button-landing effect effect-1 btn-terciary' type='submit'>
                        {userId ? 'Guardar' : 'Crear'} {loading && <div className="loader loader-form"></div>}
                    </button>
                </div>
                {error && <p className="patia-error mt-2">{errorMessage}</p>}
                {success && <p className="patia-success mt-2">{userId ? t('app.form.userEditSuccess') : t('app.form.userCreateSuccess')}</p>}
            </form>
        </div>
    );
};

export default UserForm;