import React from 'react';
import './SideMenuMobile.css';
import { ReactComponent as SidemenuHamburger } from '../../svg/SidemenuHamburger.svg';

const SideMenu = ({ navigate, getStatus }) => {

  const handleOpen = async () => {
    let sidemenubox = document.getElementById('sidemenubox');
    /*cambio el display a block para que se muestre el sidemenu*/
    sidemenubox.style.display = 'flex';
    /* muestor el icono de cerrar */
    let sidemenuclose = document.getElementById('sidemenuclose');
    sidemenuclose.style.display = 'block';
  };

  return (
    <div className="sidemenumobile-container col-1">
        <SidemenuHamburger className='mt-3' onClick={handleOpen}/>
    </div>
  );
};

export default SideMenu;
