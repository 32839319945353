import React from 'react';
import './../Landing/Landing.css';
import { ReactComponent as Logo } from '../../svg/PatiaLogoBlue.svg';
import { ReactComponent as LandingBackgroundIcon } from '../../svg/LandingBackgroundIcon.svg';
import { ReactComponent as FacebookIcon } from '../../svg/facebookIcon.svg';
import { ReactComponent as XIcon } from '../../svg/xIcon.svg';
import { ReactComponent as InstagramIcon } from '../../svg/instagramIcon.svg';
import { ReactComponent as YoutubeIcon } from '../../svg/youtubeIcon.svg';
import {useTranslation} from "react-i18next";

function TermsConditions({ navigate, getStatus, checkUserSession }) {

  const {t} = useTranslation("common");

  const handleLoginClick = async() => {
    // reviso si el usuario está logueado
    checkUserSession();
    if (getStatus().user) {
        //compruebo si es administrador
        if (getStatus().user.admin) {
            navigate('#informes');
        }else{
            navigate('#login');
        }
    }else{
        navigate('#login');
    }
  };

  return (
    <div>
        <div className='container-fluid container-landing'>
          <div className="row">
              <div className='col-4'></div>
              <div className='col-4'>
                <div className="logo-container">
                    <div role='button' onClick={() => navigate('#landing')}><Logo/></div>
                </div>
              </div>
              <div className='col-4'>
                <button className="landing-button d-none d-md-block effect effect-1 btn-primary" onClick={handleLoginClick}>{t('app.login')}</button>
              </div>
          </div>
          <div className='container-icon mt-3'>
            <LandingBackgroundIcon className='background-icon'/>
            <div>
              <h1 className='mb-5'>{t('app.terms.title')}</h1>
              <section>
                <h3>{t('app.terms.ownership.title')}</h3>
                <p>{t('app.terms.ownership.description')}</p>
              </section>
              <section>
                <h3>{t('app.terms.relevantInfo.title')}</h3>
                <p>{t('app.terms.relevantInfo.description')}</p>
              </section>
              <section>
                <h3>{t('app.terms.transactions.title')}</h3>
                <p>{t('app.terms.transactions.description')}</p>
              </section>
              <section>
                <h3>{t('app.terms.license.title')}</h3>
                <p>{t('app.terms.license.description')}</p>
              </section>
              <section>
                <h3>{t('app.terms.unauthorizedUse.title')}</h3>
                <p>{t('app.terms.unauthorizedUse.description')}</p>
              </section>
              <section>
                <h3>{t('app.terms.ownershipRights.title')}</h3>
                <p>{t('app.terms.ownershipRights.description')}</p>
              </section>
              <section>
                <h3>{t('app.terms.refundPolicy.title')}</h3>
                <p>{t('app.terms.refundPolicy.description')}</p>
              </section>
              <section>
                <h3>{t('app.terms.accountSuspension.title')}</h3>
                <p>{t('app.terms.accountSuspension.description')}</p>
              </section>
              <section>
                <h3>{t('app.terms.liability.title')}</h3>
                <p>{t('app.terms.liability.description')}</p>
              </section>
              <section>
                <h3>{t('app.terms.modifyTerms.title')}</h3>
                <p>{t('app.terms.modifyTerms.description')}</p>
              </section>
              <section>
                <h3>{t('app.terms.lawAndDisputes.title')}</h3>
                <p>{t('app.terms.lawAndDisputes.description')}</p>
              </section>
              <section className='mb-5'>
                <h3>{t('app.terms.privacy.title')}</h3>
                <p>{t('app.terms.privacy.description')}</p>
              </section>
            </div>
          </div>
        
          <div className='row mt-5 text-center'>
            <div className='col-12 col-md-3 col-xl-4 mb-5 justify-content-center justify-content-md-start footer-content-start'>
              <Logo/>
            </div>
            <div className='col-12 col-md-6 col-xl-4 mb-5 footer-self-end'>
              <div className='row footer-links'>
                <div className='col-12 col-md-6'>
                  <div role='button' onClick={() => navigate('#privacypolicy')}>{t('app.landing.privacy_policy')}</div>
                </div>
                <div className='col-12 col-md-6'>
                  <div role='button' onClick={() => navigate('#termsconditions')}>{t('app.landing.conditions')}</div>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-3 col-xl-4 mb-5 footer-self-end'>
              <div className='row justify-content-center justify-content-md-end'>
                <div className='col-auto col-icon'>
                  <a href='https://www.facebook.com/PatiaDiabetes/' target='_blank' rel='noreferrer'><FacebookIcon/></a>
                </div>
                <div className='col-auto col-icon'>
                  <a href='https://x.com/PatiaDiabetes' target='_blank' rel='noreferrer'><XIcon/></a>
                </div>
                <div className='col-auto col-icon'>
                <a href='https://www.instagram.com/patiadiabetes/' target='_blank' rel='noreferrer'><InstagramIcon/></a>
                </div>
                <div className='col-auto col-icon'>
                  <a href='https://www.youtube.com/channel/UCLSZcB9GTIYwfOl9kwdUoyQ' target='_blank' rel='noreferrer'><YoutubeIcon/></a>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default TermsConditions;