class ApiService {
    constructor() {
        this.baseUrl = 'https://patia.fidenet.net/api';
        this.filter = {
            limit: 10,
            skip: 0,
            where: [],
            order: 'id DESC',
        };
    }

    setFilter(param, value) {
        this.filter[param] = value;
        console.log('filter', this.filter);
    }

    getFilter() {
        return this.filter;
    }
  
    async request(path, method, body = null, opts = {}) {
        const url = `${this.baseUrl}/${path}`;
        //miro si el body es un objeto y lo convierto en un string
        if (body && typeof body === 'object' && !(body instanceof FormData)) {
            body = JSON.stringify(body);
        }
        //miro si el opts tiene headers y si no lo tiene lo creo, tambien miro que no sea formdata el body
        if (!opts.headers && body !== null && !(body instanceof FormData)) {
            opts.headers = {};
            opts.headers['Content-Type'] = 'application/json';
        }
        const options = {
            method,
            credentials: 'include', // Siempre envía el token en la cabecera
            ...opts,
            body: body,
            //mode: 'no-cors',
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error en la solicitud');
            }
            //console.log(path, response);
            return await response.json();
        } catch (error) {
            //console.error('Error en la petición API', error);
            //throw error;
            return null;
        }
    }
  
    get(path, useFilters = false) {
        // si filters es true, se añaden los filtros al path de la petición
        if (useFilters) {
            const filterString = JSON.stringify(this.filter);
            console.log('filterString', filterString);
            path = `${path}?filter=${encodeURIComponent(filterString)}`;
        }
        return this.request(path, 'GET');
    }
  
    post(path, body, opts = {}) {
        return this.request(path, 'POST', body, opts);
    }

    put(path, body, opts = {}) {
        return this.request(path, 'PUT', body, opts);
    }

    delete(path) {
        return this.request(path, 'DELETE');
    }
}
  
export default ApiService; 