import React from 'react';
import SideMenu from '../SideMenu/SideMenu';
import SideMenuMobile from '../SideMenuMobile/SideMenuMobile';
import List from '../List/List';
import FullBodyIMG from '../FullBodyIMG/FullBodyIMG';
import './Dashboard.css';
import { useEffect } from 'react';
import { changeLanguage } from 'i18next';

const Dashboard = ({ navigate, getStatus, checkUserSession}) => {
    const [userInfo, setUserInfo] = React.useState(null);

    // compruebo que el usuario esté logueado
    if (!getStatus().user){
        navigate('#login');
    }

    useEffect(() => {
        checkUserSession().then(userInfo => {
            console.log(userInfo);
            setUserInfo(userInfo);
            if(userInfo === null) {
                navigate('#login');
            }

            // Cambiar el idioma
            const userLang = userInfo.language;
            changeLanguage(userLang);
        }
    // eslint-disable-next-line
    )}, [checkUserSession]);
    

    return (
        <div className='container-fluid container-dashboard'>
            <FullBodyIMG />
            <div className='row'>
                <SideMenu navigate={navigate} getStatus={getStatus} userInformation={userInfo}/>
                <SideMenuMobile/>
                <div className='col-11 col-lg-10 dashboard-box'>
                    <div className='card mt-4 mb-4 row border-white'>
                        <List navigate={navigate} getStatus={getStatus} checkUserSession={checkUserSession}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
