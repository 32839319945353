import React from 'react';
import './Landing.css';
import { ReactComponent as Logo } from '../../svg/PatiaLogoBlue.svg';
import { ReactComponent as LandingBackgroundIcon } from '../../svg/LandingBackgroundIcon.svg';
import { ReactComponent as FacebookIcon } from '../../svg/facebookIcon.svg';
import { ReactComponent as XIcon } from '../../svg/xIcon.svg';
import { ReactComponent as InstagramIcon } from '../../svg/instagramIcon.svg';
import { ReactComponent as YoutubeIcon } from '../../svg/youtubeIcon.svg';
import PortadaImage from '../../img/portada-landing-patia.png';
import LandingProcessImgEsp from '../../img/Infografia-ESP.png';
import LandingProcessImgEng from '../../img/Infografia_ENG.png';
import Form from '../Form/Form';
import {useTranslation} from "react-i18next";

function Landing({ navigate, getStatus, checkUserSession }) {

  const userLang = navigator.language || navigator.userLanguage;

  const {t} = useTranslation("common");

  const handleLoginClick = async() => {
    // reviso si el usuario está logueado
    checkUserSession();
    if (getStatus().user) {
        //compruebo si es administrador
        if (getStatus().user.admin) {
            navigate('#informes');
        }else{
            navigate('#login');
        }
    }else{
        navigate('#login');
    }
  };

  return (
    <div>
        <div className='container-fluid container-landing'>
          <div className="row">
              <div className='col-4'></div>
              <div className='col-4'>
                <div className="logo-container">
                    <div role='button' onClick={() => navigate('#landing')}><Logo/></div>
                </div>
              </div>
              <div className='col-4'>
                <button className="landing-button d-none d-md-block effect effect-1 btn-primary" onClick={handleLoginClick}>{t('app.login')}</button>
              </div>
          </div>
          <div className='container-icon mt-3'>
            <LandingBackgroundIcon className='background-icon'/>
            <div className='row'>
              <div className='col-12 col-md-5 mt-5'>
                <h1 className='landing-title text-center text-md-start mb-2'>{t('app.welcometo')} <span className='txt-blue d-block'>{t('app.name')}</span></h1>
                <button className="landing-button button-big btn-primary effect effect-1 m-auto m-md-0 float-none" onClick={handleLoginClick}>{t('app.login')}</button>
              </div>
              <div className='col-12 col-md-7 mt-5'>
                <img src={PortadaImage} className='landing-first-image' alt="Portada" />
              </div>
            </div>
            <div className='row landing-blue-box'>
              <p className='m-0'>{t('app.landing.subtitle')}</p>
              <h2>{t('app.landing.title_1')}<br/>{t('app.landing.title_2')}</h2>
            </div>
          </div>
          <div className='row mt-5 landing-air'>
            <div className='col-12 col-sm-5 image-header-landing mt-3'>
              <p>{t('app.landing.form_title')}</p>
            </div>
            <div className='col-12 col-sm-7'></div>
          </div>
          <div className='row landing-air'>
            <div className='col-12 col-sm-6 col-xl-6 mt-3'>
              <img src={userLang === 'es-ES' ? LandingProcessImgEsp : LandingProcessImgEng} className='landing-second-image' alt='Landing infografia' />
            </div>
            <div className='col-12 col-sm-6 col-xl-6'>
              <Form />
            </div>
          </div>
          <div className='row mt-5 text-center'>
            <div className='col-12 col-md-3 col-xl-4 mb-5 justify-content-center justify-content-md-start footer-content-start'>
              <Logo/>
            </div>
            <div className='col-12 col-md-6 col-xl-4 mb-5 footer-self-end'>
              <div className='row footer-links'>
                <div className='col-12 col-md-6'>
                  <div role='button' onClick={() => navigate('#privacypolicy')}>{t('app.landing.privacy_policy')}</div>
                </div>
                <div className='col-12 col-md-6'>
                  <div role='button' onClick={() => navigate('#termsconditions')}>{t('app.landing.conditions')}</div>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-3 col-xl-4 mb-5 footer-self-end'>
              <div className='row justify-content-center justify-content-md-end'>
                <div className='col-auto col-icon'>
                  <a href='https://www.facebook.com/PatiaDiabetes/' target='_blank' rel='noreferrer'><FacebookIcon/></a>
                </div>
                <div className='col-auto col-icon'>
                  <a href='https://x.com/PatiaDiabetes' target='_blank' rel='noreferrer'><XIcon/></a>
                </div>
                <div className='col-auto col-icon'>
                <a href='https://www.instagram.com/patiadiabetes/' target='_blank' rel='noreferrer'><InstagramIcon/></a>
                </div>
                <div className='col-auto col-icon'>
                  <a href='https://www.youtube.com/channel/UCLSZcB9GTIYwfOl9kwdUoyQ' target='_blank' rel='noreferrer'><YoutubeIcon/></a>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Landing;
