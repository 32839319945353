import React, { useState, useEffect, useMemo } from 'react';
import './List.css';
import Filters from '../Filters/Filters';
import Table from '../Table/Table';
import ApiService from '../../service/ApiService';
import Paginator from '../Paginator/Paginator';
import InfoBox from '../InfoBox/InfoBox';
import SendFiles from '../SendFiles/SendFiles';
import TableBox from '../TableBox/TableBox';
import InfoTable from '../InfoTable/InfoTable';
import {useTranslation} from "react-i18next";
// import { ReactComponent as ActionBtn1 } from '../../svg/ActionBtn1.svg';
// import { ReactComponent as ActionBtn2 } from '../../svg/ActionBtn2.svg';
// import { ReactComponent as ActionBtn3 } from '../../svg/ActionBtn3.svg';

const LIST_TYPE = {
    SUBIDAS: 0,
    INFORMES: 1,
    GENOMAS: 2,
    USUARIOS: 3,
    INSTITUCIONES: 4,
};

function List({ navigate, getStatus, checkUserSession}) {
    const [listData, setListData] = useState([]);
    const [listData2, setListData2] = useState(null);
    const [showGenomePopup, setShowGenomePopup] = useState(false);
    const [genomeData, setGenomeData] = useState(null);
    const [filter, setFilter] = useState(false);
    const [pagination, setPagination] = useState(false);
    //const [backbutton, setBackbutton] = useState(false);
    const [errorData, setErrorData] = useState(false);
    const [canSee, setCanSee] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canDownload, setCanDownload] = useState(false);
    const [canUpload, setCanUpload] = useState(false);
    const [pages, setPages] = useState(0);
    const [pages2, setPages2] = useState(0);
    const [user, setUser] = useState(null);
    const [group, setGroup] = useState(null);
    const [person, setPerson] = useState(null);
    const [personID, setPersonID] = useState(null);
    const [options1, setOptions1] = useState(null);
    const [options2, setOptions2] = useState(null);
    const [total, setTotal] = useState(0);
    const [total2, setTotal2] = useState(0);
    const [title, setTitle] = useState("Listado de usuarios");
    const [subtitle, setSubtitle] = useState("Usuarios");
    const [subsubtitle, setSubSubtitle] = useState(null);
    const [stop, setStop] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tableBoxData, setTableBoxData] = useState(null);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [isUserView, setIsUserView] = useState(false);
    const [isInformesView, setIsInformesView] = useState(false);
    const [laboratoryName, setLaboratoryName] = useState('');
    const [fileName, setFileName] = useState('');
    const [uploadDate, setUploadDate] = useState('');
    const [uploadBy, setUploadBy] = useState('');
    const [currentUser, setCurrentUser] = useState({ id: null, email: null });
    let searchFilter = false;
    const {t} = useTranslation("common");
    const [uploadUser, setUploadUser] = useState(1);
    const [institutionId, setInstitutionId] = useState(null);

    const apiService = useMemo(() => {
        return new ApiService();
    },[]);

    const apiService2 = useMemo(() => {
        return new ApiService();
    },[]);

    useEffect(() => {
        updateBreadcrumbs({});
        if(stop) return;

        // Obtener el id de la institución
        checkUserSession().then(userInfo => {
            console.log('userInfo', userInfo);
            if (userInfo.idInstitucion && userInfo.idInstitucion !== '0') {
                setInstitutionId(userInfo.idInstitucion);
            }else {
                setInstitutionId(userInfo.id);
            }

            if(!listData || !listData.length){
                if(getStatus().user.admin){
                    getData(LIST_TYPE.INSTITUCIONES);
                } else if(getStatus().user.insti){
                    getData(LIST_TYPE.USUARIOS, userInfo.id);
                }else{
                    getData(LIST_TYPE.SUBIDAS);
                }
            }
            if(!options1 || !options1.length){
                getOptions1();
            }
            if(!options2 || !options2.length){
                getOptions2();
            }

            setUploadUser(userInfo.id);
        });
    }, [navigate, getStatus, checkUserSession]);

    const resetState = () => {
        console.log('RESETEO EL ESTADO');
        setPages2(0);
        setTotal2(0);
        setPages(0);
        setTotal(0);
        setStop(true);
        setLoading(true);
        apiService.setFilter('order', 'id DESC');
        apiService.setFilter('limit', 10);
        apiService.setFilter('where', {});
    };

    const configureFiltersForType = (type, id, emailUser) => {
        let model;
        const isAdmin = getStatus().user.admin;
        const isInstitution = getStatus().user.insti;
        let uiConfig = {
            subsubtitle: null,
        };

        const emailToShow = isAdmin && emailUser ? emailUser : getStatus().user.usuario;

        switch (type) {
            case LIST_TYPE.SUBIDAS:
                setIsUserView(false);
                setIsInformesView(false);
                if(isAdmin || isInstitution){
                    let oldFilter = apiService.getFilter();
                    oldFilter.where['user'] = ["user",id ? id : user,'='];
                    apiService.setFilter('where',oldFilter.where);
                }
                model = 'grouplist.php';
                uiConfig = {
                    title: t('app.reports.titleUploads'),
                    subtitle: t('app.reports.titleUploadsBy') + emailToShow,
                    filter: true,
                    pagination: true,
                    //backbutton: isAdmin,
                    canSee: true,
                    canEdit: false,
                    canDownload: false,
                    canUpload: false,
                };
                break;
            case LIST_TYPE.INFORMES:
                setIsUserView(true);
                setIsInformesView(true);
                apiService.setFilter('where',{
                    group: ["group",id ? id : group,'='],
                    calculo: ["calculoSN",'ok','LIKE'],
                    estado: ["status",'ok','LIKE']
                });
                apiService2.setFilter('where',{
                    group: ["group",id ? id : group,'='],
                    calculo: ["error",'a:0:{}','!=']
                });

                model = 'list.php';
                uiConfig = {
                    title: "Listado de Informes",
                    subtitle: t('app.reports.titleUploadsBy') + emailToShow,
                    subsubtitle: t('app.reports.reportsFinished'),
                    filter: true,
                    pagination: true,
                    //backbutton: true,
                    canSee: true,
                    canEdit: false,
                    canDownload: true,
                    canUpload: false
                };
                break;
            case LIST_TYPE.GENOMAS:
                setIsInformesView(false);
                setIsUserView(false);
                apiService.setFilter('limit', 100);
                apiService.setFilter('where',{
                    group: ["group",group,'='],
                    personid: ["personid",id ? id : person,'=']
                });
                model = 'gen.php';
                uiConfig = null;
                break;
            case LIST_TYPE.USUARIOS:
                console.log("listando usuarios de la institucion: " + id);
                setIsInformesView(false);
                setIsUserView(true);
                //setear un filtro que obtenga los usuarios que sean de la institucion id que llega por parametros
                if(id) {
                    let oldFilter = apiService.getFilter();
                    oldFilter.where['idInstitucion'] = ["idInstitucion",parseInt(id),'='];
                    oldFilter.where['roll'] = ["roll","user",'='];
                    apiService.setFilter('where',oldFilter.where);
                }
                
                model = 'users.php';
                uiConfig = {
                    title: t('app.reports.titleUsers'),
                    subtitle: t('app.reports.users') + (emailUser ? ' of ' + emailUser : ''),
                    filter: true,
                    pagination: true,
                    //backbutton: false,
                    canSee: true,
                    canEdit: true,
                    canDownload: false,
                    canUpload: true
                };
                break;
            case LIST_TYPE.INSTITUCIONES:
                setIsInformesView(false);
                setIsUserView(true);
                let oldFilter = apiService.getFilter();
                oldFilter.where['roll'] = ["roll","institucion",'='];
                apiService.setFilter('where',oldFilter.where);
                model = 'users.php';
                uiConfig = {
                    title: t('app.reports.institutions') ,
                    subtitle: t('app.reports.institutions'),
                    filter: true,
                    pagination: true,
                    //backbutton: false,
                    canSee: true,
                    canEdit: true,
                    canDownload: false,
                    canUpload: true
                };
                break;
            default:
                model = 'grouplist.php';
                if(isAdmin){
                    let oldFilter = apiService.getFilter();
                    oldFilter.where['user'] = ["user",id ? id : user,'='];
                    apiService.setFilter('where',oldFilter.where);
                }
                uiConfig = {
                    title: t('app.reports.titleUploads'),
                    subtitle: t('app.reports.uploads'),
                    filter: true,
                    pagination: true,
                    //backbutton: isAdmin,
                    canSee: true,
                    canEdit: false,
                    canDownload: false,
                    canUpload: false
                };
                break;
        }
        if(uiConfig){
            updateUIConfig(uiConfig);
        }
        return model;
    };

    const getOptions1 = async () => {
        try {
            const result = await apiService.get('distinct.php?table=erabiltzaileak&column=pais');

            if(result.data){
                result.data = result.data.map(item => {
                    return {
                        value: item.pais,
                        label: item.pais
                    }
                });
                //añado la opcion de todos
                result.data.unshift({
                    value: '',
                    label: 'País'
                });
                setOptions1(result.data); // Actualizamos el estado con los datos
                console.log('opciones1',result,result.data);
            }
            
        } catch (error) {
            
        }
    }

    const getOptions2 = async () => {
        try {
            const result = await apiService.get('distinct.php?table=erabiltzaileak&column=erakundea');
            if(result.data){
                result.data = result.data.map(item => {
                    return {
                        value: item.erakundea,
                        label: item.erakundea
                    }
                });
                //añado la opcion de todos
                result.data.unshift({
                    value: '',
                    label: 'Cliente'
                });
                setOptions2(result.data); // Actualizamos el estado con los datos
                console.log('opciones2',result,result.data);
            }
        } catch (error) {

        }
    }

    const updateUIConfig = (config) => {
        setTitle(config.title);
        setSubtitle(config.subtitle);
        setSubSubtitle(config.subsubtitle || null);
        setFilter(config.filter);
        setPagination(config.pagination);
        //setBackbutton(config.backbutton);
        setCanSee(config.canSee);
        setCanEdit(config.canEdit);
        setCanDownload(config.canDownload);
        setCanUpload(config.canUpload);
        setTableBoxData(config.tableBoxData || false);
        setListData2(null);
    };

    const getData = async (type = LIST_TYPE.SUBIDAS, id = null, emailUser = null) => {

        console.log('getData', type, id, emailUser);
        setCurrentUser({ id: id, email: emailUser });
        if (type !== LIST_TYPE.GENOMAS && !searchFilter){
            resetState();
        }

        const model = configureFiltersForType(type, id, emailUser);
        
        if (type === LIST_TYPE.SUBIDAS){
            if(getStatus().user.admin){
                //setBackbutton(true);
            }else{
                //setBackbutton(false);
            }
        }

        try {
            const result = await apiService.get(model, true);
            if(type === LIST_TYPE.GENOMAS){
                if(result){
                    setGenomeData(result.data);
                    console.log(result);
                }
            }else{
                if(result){
                    setListData(result.data);
                    setPages(result.count/apiService.getFilter().limit);
                    setTotal(result.count);
                    console.log(result);
                }
            }
            if(type === LIST_TYPE.INFORMES){
                const result2 = await apiService2.get(model, true);
                if(result2){
                    setListData2(result2.data);
                    setPages2(result2.count/apiService2.getFilter().limit);
                    setTotal2(result2.count);
                    setLaboratoryName(result2.data[0].erakundea);
                    setFileName(result2.data[0].filegen + " - " + result2.data[0].filemed);
                    console.log(result2);
                }
            }else if(type !== LIST_TYPE.GENOMAS){
                setSubSubtitle(null);
            }
        } catch (error) {
            console.error('Error fetching data', error);
            resetState();
        }
        setLoading(false);
    }

    const edit = async (row) => {
        console.log(row);
        try {
            if(row.roll === "institucion"){
                navigate('#institution?id=' + row.id);
            }else if(row.roll === "user"){
                navigate('#user?id=' + row.id);
            }
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    const see = async (row) => {
        searchFilter = false;
        console.log(row);
        console.log("Emaila" + row.emaila);
        console.log("Useremail" + row.useremail);
        if (row.roll){
            console.log("Entro en el primer if" + row.emaila);
            setUser(row.id);
            setCurrentUser({ id: row.id, email: row.emaila });
            if(row.roll === "institucion"){
                // getData(LIST_TYPE.USUARIOS, row.id, row.emaila);
                navigate('#admin?id=' + row.id);
            } else {
                console.log("Entro en el get data de subidas con el id: " + row.id + " y el email: " + row.emaila);
                getData(LIST_TYPE.SUBIDAS,row.id, row.emaila);
            }
        }else if (row.group){
            console.log("Entro en el segundo if");
            if (row.personid){
                console.log("Entro en el tercer if");
                setPersonID(row.personid);
                // si hay errores
                if(row.calculoError || (row.error && row.error !== "a:0:{}")){
                    setErrorData({
                        "Errores Genoma": row.calculoError ? row.calculoError : "No hay errores",
                        "Errores Cliente": row.error && row.error !== "a:0:{}" ? row.error.split('"').filter((item, index) => index%2 === 1).join('<br>') : "No hay errores",
                    });
                    setGenomeData(null);
                }else{
                    setErrorData(null);
                    getData(LIST_TYPE.GENOMAS,row.personid);
                }
                setShowGenomePopup(true);
            } else {
                console.log("Entro en el cuarto if");
                setCurrentUser({ id: row.id, email: row.useremail });
                setGroup(row.group);
                setUploadBy(row.useremail);
                setUploadDate(row.dia+" - "+row.hora);
                setTableBoxData({
                    "Laboratory": row.erakundea,
                    "Nombre del fichero": row.filegen + " / " + row.filemed,
                    "Uploaded on": row.dia+" - "+row.hora,
                    "Uploaded by": row.useremail
                });
                getData(LIST_TYPE.INFORMES,row.group, row.useremail);
            }
        }
        updateBreadcrumbs(row);
    }

    // Función para actualizar las migas de pan
    const updateBreadcrumbs = (row) => {
        let newBreadcrumbs = [];
        let usersLabel = '';
        if (getStatus().user.admin) {
            usersLabel = t('app.reports.institutions');
        } else {
            usersLabel = t('app.reports.users');
        }
        // if (getStatus().user.admin) {
        //     newBreadcrumbs.push({ label: usersLabel, onClick: () => navigateTo('usuarios') });
        // }
        if (getStatus().user.insti) {
            newBreadcrumbs.push({ label: usersLabel, onClick: () => navigateTo('usuarios', getStatus().user.id) });
        }
        if (user) {
            newBreadcrumbs.push({ label: t('app.reports.uploads'), onClick: () => navigateTo('subidas', currentUser.email) });
        }
        if (!getStatus().user.admin && !getStatus().user.insti) {
            newBreadcrumbs.push({ label: t('app.reports.uploads'), onClick: () => navigateTo('subidas', currentUser.email) });
        }
        if (group) {
            newBreadcrumbs.push({ label: t('app.reports.reports'), onClick: () => navigateTo('informes', currentUser.email) });
        }
        if (person) {
            newBreadcrumbs.push({ label: t('app.reports.results'), onClick: () => navigateTo('resultados', currentUser.email) });
        }
        setBreadcrumbs(newBreadcrumbs);
    }

    // Función para navegar a partir de las migas de pan
    const navigateTo = (path, emailUser = null) => {
        searchFilter = false;
        console.log('navigateTo', emailUser);
        switch (path) {
            case 'usuarios':
                setUser(null);
                setGroup(null);
                setPerson(null);
                if(getStatus().user.insti){
                    getData(LIST_TYPE.USUARIOS, getStatus().user.id);
                }else {
                    getData(LIST_TYPE.USUARIOS);
                }

                
                break;
            case 'subidas':
                setGroup(null);
                setPerson(null);
                getData(undefined, undefined, emailUser);
                break;
            case 'informes':
                setPerson(null);
                getData(LIST_TYPE.INFORMES, undefined, emailUser);
                break;
            case 'resultados':
                getData(LIST_TYPE.GENOMAS);
                break;
            default:
                break;
        }
        updateBreadcrumbs({});
    }

    // Renderizar las migas de pan
    const renderBreadcrumbs = () => {
        return (
            <div className="breadcrumbs">
                {breadcrumbs.map((breadcrumb, index) => (
                    <span key={index} onClick={breadcrumb.onClick} className='clickable-text'>
                        {breadcrumb.label} {index < breadcrumbs.length - 1 && ' > '}
                    </span>
                ))}
            </div>
        );
    }

    const download = async (row) => {
        console.log(row);
        let url = 'https://patia.fidenet.net/api/download.php?id='+row.id;
        // habro en una pestaña nueva en el navegador para descargar el fichero
        window.open(url, "_blank", "noreferrer");
    };

    const upload = async (row) => {
        console.log(row);
        navigate('#uploadreport?id=' + row.emaila + '&identificator=' + row.id);
    };

    const reload = async () => {
        if(person){
            getData(LIST_TYPE.GENOMAS);
        }else if(group){
            getData(LIST_TYPE.INFORMES);
        }else{
            if(getStatus().user.admin){
                if(user){
                    getData(LIST_TYPE.SUBIDAS);
                }else{
                    getData(LIST_TYPE.USUARIOS);
                }
            }else{
                getData();
            }
        }
    };

    const onChangeFilter = async (e) => {
        let value = e.target.value;
        const param = e.target.name;
        let oldFilter = apiService.getFilter();
        if (value === '') {
            delete oldFilter.where[param];
            searchFilter = false;
        } else {
            searchFilter = true;

            if(param === 'group'){
                if (value.length === 4) {
                    const year = parseInt(value);
                    value = new Date(year, 0, 1).getTime().toString();
                } else {
                    value = new Date(value).getTime().toString();
                }
            }

            console.log('value', value);
            console.log('param', param);

            if (oldFilter.where[param]) {
                oldFilter.where[param] = [param, value, param === "group" ? '>=' : 'LIKE'];
            } else {
                oldFilter.where[param] = [param, value, param === "group" ? '>=' : 'LIKE'];
            }
        }

        checkUserSession().then(userInfo => {
            if(userInfo.insti) {
                oldFilter.where['idInstitucion'] = ["idInstitucion",parseInt(userInfo.id),'='];
            }
            if(userInfo.admin) {
                oldFilter.where['roll'] = ["roll","institucion",'='];
            }

            apiService.setFilter('where',oldFilter.where);
            console.log('filter', oldFilter);
            reload();
        
        });
    };

    const onChangeOrder = async (order) => {
        // // si la columna es edit o see no hago nada
        // if (order.column === 'edit' || order.column === 'see') return;
        // console.log(order);
        // let oldFilter = apiService.getFilter();
        // oldFilter.order = `\`${order.column}\` ${order.direction}`;
        // apiService.setFilter('order',oldFilter.order);
        // reload();
    };

    const onChangePage = async (page) => {
        let limit = apiService.getFilter().limit;
        let oldFilter = apiService.getFilter();
        oldFilter.skip = (page-1) * limit;
        apiService.setFilter('skip',oldFilter.skip);
        if(person){
            getData(LIST_TYPE.GENOMAS, person);
        }else if(group){
            getData(LIST_TYPE.INFORMES, group);
        }else if(getStatus().user.admin && !user){
            getData(LIST_TYPE.USUARIOS);
        }else{
            getData(LIST_TYPE.SUBIDAS, user);
        }
    }

    const onChangePage2 = async (page) => {
        let limit = apiService2.getFilter().limit;
        let oldFilter = apiService.getFilter();
        oldFilter.skip = (page-1) * limit;
        apiService2.setFilter('skip',oldFilter.skip);
        reload();
    }

    const closePopup = () => {
        setShowGenomePopup(false);
    }

    /*const goBack = () => {
        if(person){
            setPerson(null);
            getData(LIST_TYPE.INFORMES, group);
        }else if(group){
            setGroup(null);
            getData(LIST_TYPE.SUBIDAS, user);
        }else if(user){
            setUser(null);
            getData(LIST_TYPE.USUARIOS);
        }else{
            navigate('#home');
        }
    }*/
      
    return (
        <div className='mb-4 ps-3'>
            {showGenomePopup && (
                <div className="genome-popup">
                    <div className="genome-popup-content">
                        <div className='d-flex justify-content-between align-items-center'>
                            <h5 className='mb-4'>{personID}</h5>
                            <p className='clickable-text' onClick={() => closePopup()}>Cerrar</p>
                        </div>
                        {errorData && <TableBox data={errorData}/>}
                        {genomeData && <Table data={genomeData} />}
                    </div>
                </div>
            )}
            <div className="breadcrumbs mt-3 mb-2">
                {renderBreadcrumbs()}
            </div>
            {/*backbutton && <p className='mt-4' onClick={() => goBack()}>Volver</p>*/}
            
            <div className='row mx-0'>
                <div className='col-12 p-0'>
                    {!tableBoxData && !isUserView && !getStatus().user.admin && <SendFiles navigate={navigate} getStatus={getStatus} id={uploadUser} institutionId={institutionId}/>}
                    {tableBoxData && <TableBox data={tableBoxData}/>}
                </div>
            </div>

            {!isUserView ? <h3 className={`${getStatus().user.admin ? '' : 'mt-5'} mb-3`}>{title}</h3> : ''}
            <div className='mb-3 row'>
                <div className='col-3'>
                    <InfoBox title={getStatus().user.usuario} number={Number(total) + Number(total2)} subtitle={t('app.reports.totalData')} />
                </div>
                
                {isInformesView && <InfoTable laboratory={laboratoryName} fileName={fileName} uploadDate={uploadDate} uploadBy={uploadBy} />}
            </div>
            {/* {listData2 && (
                <>
                <button className='btn btn-primary action-btn' ><ActionBtn1 width="12" height="12" className='list-action-icon'/> Descargar</button>
                <button className='btn btn-primary action-btn' ><ActionBtn2 width="12" height="12" className='list-action-icon'/> Exportar Resultados</button>
                <button className='btn btn-primary action-btn' ><ActionBtn3 width="12" height="12" className='list-action-icon'/> Descargar ZIP</button>
                </>
            )} */}
            <Filters onChangeFilter={onChangeFilter} options1={options1} options2={options2} active={filter} title={subtitle} subtitle={subsubtitle} subtitleClass="subtitle-blue" t={t} navigate={navigate}/>
            {loading ? <div class="loader"></div> : <Table data={listData} edit={canEdit ? edit : null} see={canSee ? see : null} download={canDownload ? download : null} upload={canUpload ? upload : null} onChangeOrder={onChangeOrder} loading={loading} />}
            {pagination && <Paginator onChangePage={onChangePage} count={pages} />}
            {listData2 && <Filters active={false} subtitle={t('app.reports.reportsUnfinished')} subtitleClass="subtitle-orange" />}
            {listData2 && <Table ignoreCol="calculo" data={listData2} edit={canEdit ? edit : null} see={canSee ? see : null} download={null} upload={canUpload ? upload : null} onChangeOrder={onChangeOrder} loading={loading} />}
            {listData2 && <Paginator onChangePage={onChangePage2} count={pages2} />}
            {/*backbutton && <p className='mt-2' onClick={() => goBack()}>Volver</p>*/}
        </div>
    );
}

export default List;