import React from 'react';
import { useEffect } from 'react';

const Loading = ({checkUserSession, navigate, getStatus}) => {
    
    useEffect(() => {
        checkUserSession();
        if (getStatus().user) {
            //compruebo si es administrador
            if (getStatus().user.admin) {
                navigate('#informes');
            }else{
                navigate('#login');
            }
        }
        navigate('#');
    }, [checkUserSession, navigate, getStatus]);
    
    return (<div></div>);
};

export default Loading;
