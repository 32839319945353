import React, { useState, useEffect } from 'react';
import ApiService from '../../service/ApiService';
import './../Login/Login.css';
import { ReactComponent as IconSvg } from '../../svg/PatiaIcon.svg'
import { ReactComponent as LogoSvg } from '../../svg/PatiaLogo.svg'

const TwoFactor = ({checkUserSession, getStatus, navigate}) => {
  const [code, setCode] = useState('');
  const [verify, setVerify] = useState(false);
  const [error, setError] = useState(false);
  const apiService = new ApiService();

  useEffect(() => {
    if(!verify){
      checkUserSession().then(userInfo => {
        console.log('user',userInfo);
        if (userInfo) {
          if (userInfo.admin) {
            navigate('#informes');
          }else{
            navigate('#welcome');
          }
        }
      });
      setVerify(true);
    }
  },[setVerify, verify, checkUserSession, navigate]);


  const handleTwoFa = async (e) => {
    e.preventDefault();
  
    try {

      const body = `code=${encodeURIComponent(code)}`;
      const options = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
  
      apiService.post('twofa.php', body, options)
      .then(response => {
        if (!response) throw new Error('Not authorized');
        // si el usuario es administrador lo redirijo al dashboard
        //console.log("login hecho",response);
        checkUserSession().then(userInfo => {
          console.log('user',userInfo);
          if (userInfo) {
            if (userInfo.admin) {
              navigate('#informes');
            }else{
              navigate('#welcome');
            }
          }else{
            navigate('#login');
          }
        });
      })
      .catch(error => {
        console.error('Code error', error);
        setError(true);
      });
    } catch (error) {
      console.error("Code error", error);
      setError(true);
    }
  };


  return (
    <div className="patia-container-background">
      <IconSvg className="patia-icon" />
      <div className="patia-login-container">
        <div className="patia-login-background"> 
          <div className="patia-logo-container">
            <LogoSvg className="patia-logo" />
          </div>
        </div>
        <div className="patia-login-form-container">
          <p  className="patia-need-help"></p>
          {
            !getStatus().user ?
              <form className='patia-form patia-form-user' onSubmit={handleTwoFa}>
                <div className='patia-form-container'>
                  <h2 className='mb-1 patia-subheading'>Código de verificación</h2>
                  <p className='patia-subheading-p mb-4'>Introduce el código de verificación que hemos enviado a tu correo electrónico.</p>
                  <div className='d-flex flex-column mb-3 patia-form-input'>
                    <input type="text" name="code" value={code} onChange={(e) => setCode(e.target.value)} required minLength={1}/>
                    <label htmlFor='email'>Código</label>
                  </div>
                  {error && <p className="patia-error">Código de verificación incorrecto, inténtalo de nuevo.</p>}
                  <button type="submit" className='effect effect-1 btn-primary'>Enviar</button>
                  
                </div>
              </form>
            :
            getStatus().user.admin ? navigate('#informes') : navigate('#welcome')
          }
        </div>
      </div>
    </div>
  )
}

export default TwoFactor