import React, {useEffect, useState} from 'react'
import SideMenu from '../SideMenu/SideMenu';
import SideMenuMobile from '../SideMenuMobile/SideMenuMobile';
import FullBodyIMG from '../FullBodyIMG/FullBodyIMG';
import PDFForm from '../PDFForm/PDFForm';
import ApiService from '../../service/ApiService';
import { useTranslation } from 'react-i18next';

const CustomizePDFPage = ({navigate, getStatus, checkUserSession}) => {
    const apiService = new ApiService();
    const [userInfo, setUserInfo] = useState(null);
    const [institution, setInstitution] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        // Verificar que el usuario esté logueado
        const userStatus = getStatus();
        if (!userStatus.user) {
            navigate('#login');
        }

        checkUserSession().then(userInfo => {
            console.log(userInfo);
            setUserInfo(userInfo);
            if(userInfo === null) {
                navigate('#login');
            }

            //obtener la info de la institucion haciendo un get a users.php con el filtro de id
            let oldFilter = apiService.getFilter();
            oldFilter.where['id'] = ["id",userInfo.idInstitucion,'='];
            apiService.setFilter('where',oldFilter.where);
            apiService.get('users.php?id=' + userInfo.idInstitucion)
                .then((response) => {
                    console.log('Success:', response);
                    const institution = response.data;
                    setInstitution(institution);
                })
                .catch((error) => {
                    console.error('Error:', error); 
                }
            );
        });

        

    }, []); 
  return (
    <div className='container-fluid container-dashboard'>
        <FullBodyIMG />
        <div className='row'>
            <SideMenu navigate={navigate} getStatus={getStatus} />
            <SideMenuMobile/>
            <div className='col-11 col-lg-10 dashboard-box'>
                <div className='card mt-4 mb-4 pt-5 row border-white'>
                    <h4 className='col-12 ms-2'>{t('app.customPDF.title')}</h4>
                    <p className='col-12 fw-semibold ms-2'>{t('app.customPDF.subtitle')}
                    </p>
                    <PDFForm institution={institution}/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CustomizePDFPage