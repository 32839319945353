import React from 'react'
import { useState, useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import {useTranslation} from "react-i18next";

const Form = () => {
    const {t} = useTranslation("common");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const form = useRef();
    const nameRef = useRef();
    const emailRef = useRef();
    const contentRef = useRef();

    useEffect(() => emailjs.init('lQgpMkxCnLhrNHnXy'), []);

    const handleFocusLabelName = (e) => {
    const label = document.getElementById('nameLabel');
    label.classList.add('active');
    }

    const handleBlurLabelName = (e) => {
    const label = document.getElementById('nameLabel');
    if (e.target.value === '') {
        label.classList.remove('active');
    }
    }

    const handleFocusLabelEmail = (e) => {
    const label = document.getElementById('emailLabel');
    label.classList.add('active');
    }

    const handleBlurLabelEmail = (e) => {
    const label = document.getElementById('emailLabel');
    if (e.target.value === '') {
        label.classList.remove('active');
    }
    }

    const handleFocusLabelText = (e) => {
    const label = document.getElementById('textLabel');
    label.classList.add('active');
    }

    const handleBlurLabelText = (e) => {
    const label = document.getElementById('textLabel');
    if (e.target.value === '') {
        label.classList.remove('active');
    }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const serviceId = "service_gocdiju";
        const templateId = "template_s2qbn3j";
        try {
          setLoading(true);
          await emailjs.send(serviceId, templateId, {
            name: nameRef.current.value,
            email: emailRef.current.value,
            content: contentRef.current.value,
          });
            setSuccess(true);
        } catch (error) {
          setError(true);
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

  return (
    <form className='row landing-form' ref={form} onSubmit={handleSubmit}>
        <div className='input-group col-6'>
            <label id='nameLabel'>{t("app.landing.form_name")}</label>
            <input ref={nameRef} onFocus={handleFocusLabelName} onBlur={handleBlurLabelName} type='text' className='form-control' placeholder='Introduzca su nombre' aria-label="Recipient's username" required/>
        </div>
        <div className='input-group col-6'>
            <label id='emailLabel'>{t("app.landing.form_email")}</label>
            <input ref={emailRef} name='email' onFocus={handleFocusLabelEmail} onBlur={handleBlurLabelEmail} type='email' className='form-control' placeholder='Introduzca su email' aria-label="Recipient's username" required/>
        </div>
        <div className='input-group col-12'>
            <label id='textLabel' className='mt-4'>{t("app.landing.form_question")}</label>
            <textarea ref={contentRef} name='content' onFocus={handleFocusLabelText} onBlur={handleBlurLabelText} rows="4" className='form-control mb-5' aria-label="Recipient's username" required/>
        </div>
        <div className='input-group col-12'>
            <button className='btn button-landing effect effect-1 btn-terciary' type='submit'>{loading ? <div class="loader loader-form"></div> : t("app.landing.form_send")}</button>
        </div>
        {error && <p className="patia-error mt-2">{t("app.landing.form_error")}</p>}
        {success && <p className="patia-success mt-2">{t("app.landing.form_success")}</p>}
    </form>
  )
}

export default Form