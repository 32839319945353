import React, { useEffect } from 'react'
import {useTranslation} from "react-i18next";

const InfoTable = ({laboratory, fileName, uploadDate, uploadBy}) => {

    useEffect(() => {
        console.log('laboratory', laboratory);
        console.log('fileName', fileName);
        console.log('uploadDate', uploadDate);
        console.log('uploadBy', uploadBy);
    }, [laboratory, fileName, uploadDate, uploadBy])

    const {t} = useTranslation("common");

  return (
    <div className='col-4'>
        <table className='tabla-subidas'>
            <tr>
                <td>{t('app.reports.laboratory')}</td>
                <td>{laboratory}</td>
            </tr>

            <tr>
                <td>{t('app.reports.filename')}</td>
                <td>{fileName}</td>
            </tr>

            <tr>
                <td>{t('app.reports.uploadDate')}</td>
                <td>{uploadDate}</td>
            </tr>

            <tr>
                <td>{t('app.reports.uploadBy')}</td>
                <td>{uploadBy}</td>
            </tr>
        </table>
    </div>
  )
}

export default InfoTable