import React, {useEffect, useState} from 'react'
import UserForm from '../UserForm/UserForm'
import SideMenu from '../SideMenu/SideMenu';
import SideMenuMobile from '../SideMenuMobile/SideMenuMobile';
import FullBodyIMG from '../FullBodyIMG/FullBodyIMG';
import ApiService from '../../service/ApiService';
import {useTranslation} from "react-i18next";

const AdminPage = ({navigate, getStatus}) => {
    const [facs, setFacs] = useState([]);
    const [institutionId, setInstitutionId] = useState(null);
    const [institutions, setInstitutions] = useState([]);
    const apiService = new ApiService();
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15; // Número de elementos por página

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = facs.slice(indexOfFirstItem, indexOfLastItem);
    const [countFacs, setCountFacs] = useState(0);
    const {t} = useTranslation("common");

    useEffect(() => {
        setLoading(true);
        // Verificar que el usuario esté logueado
        const userStatus = getStatus();
        if (!userStatus.user) {
            navigate('#login');
        }

        //get id from url
        const getIdFromUrl = () => {
            const queryParams = new URLSearchParams(window.location.search);
            return queryParams.get('id');
        };

        const id = getIdFromUrl();
        if (id) {
            setInstitutionId(id);
            console.log('id', id);
        }

        //peticion a users.php para obtener los datos de las instituciones
        const filter = {
            limit: 10,
            skip: 0,
            where: {
                roll: ["roll", "institucion", "="]
            },
            order: "id DESC"
        };

        const encodedFilter = encodeURIComponent(JSON.stringify(filter));
        const url = `users.php?filter=${encodedFilter}`;
        apiService.get(url).then(response => {
            console.log('institutions', response);
            setInstitutions(response.data);
        });

        const filterFacs = {
            skip: 0,
            where: {},
            order: "id DESC"
        };

        if (id) {
            filterFacs.where.idInstitucion = ["f.idInstitucion", id, "="];
        }

        const encodedFilterFacs = encodeURIComponent(JSON.stringify(filterFacs));
        const urlFacs = `facturacion.php?filter=${encodedFilterFacs}`;

        //peticion a facturacion.php?distinct=true para obtener los datos de facturacion de cada usuario
        apiService.get(urlFacs).then(response => {
            console.log('facs', response);
            //filter facs where idinstitucion greater than 0
            const filteredFacs = response.data.filter(fac => fac.idinstitucion > 0);
            setFacs(filteredFacs);
            setCountFacs(filteredFacs.length);
            setLoading(false);
        }
        );
    }, []);

    const nextPage = () => {
        if (currentPage < Math.ceil(facs.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };
    
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const changeFilter = () => {
        setLoading(true);
        const institution = document.getElementById('institution').value;
        const status = document.getElementById('status').value;
        const start = document.getElementById('start').value;
        const end = document.getElementById('end').value;
        let filter = {
            skip: 0,
            where: {
            },
            order: "id DESC"
        };

        if (institution) {
            filter.where.idInstitucion = ["f.idInstitucion", institution, "="];
        }
        if (status) {
            filter.where.status = ["status", status, "="];
        }
        if (start) {
            filter.where.date = ["date", start, ">="];
        }
        if (end) {
            filter.where.date = ["date", end, "<="];
        }

        const encodedFilter = encodeURIComponent(JSON.stringify(filter));
        const url = `facturacion.php?filter=${encodedFilter}`;
        apiService.get(url).then(response => {
            console.log('facs', response);
            //filter facs where idinstitucion greater than 0
            const filteredFacs = response.data.filter(fac => fac.idinstitucion > 0);
            setFacs(filteredFacs);
            setCountFacs(filteredFacs.length);
            setLoading(false);
        });
    }

    const cleanFilters = () => {
        setLoading(true);
        setCurrentPage(1);
        document.getElementById('institution').value = '';
        document.getElementById('status').value = '';
        document.getElementById('start').value = '';
        document.getElementById('end').value = '';
        const filter = {
            skip: 0,
            where: {},
            order: "id DESC"
        };

        const encodedFilter = encodeURIComponent(JSON.stringify(filter));
        const url = `facturacion.php?filter=${encodedFilter}`;
        apiService.get(url).then(response => {
            console.log('facs', response);
            //filter facs where idinstitucion greater than 0
            const filteredFacs = response.data.filter(fac => fac.idinstitucion > 0);
            setFacs(filteredFacs);
            setCountFacs(filteredFacs.length);
            setLoading(false);
        });
    }

  return (
    <div className='container-fluid container-dashboard'>
        <FullBodyIMG />
        <div className='row'>
            <SideMenu navigate={navigate} getStatus={getStatus} />
            <SideMenuMobile/>
            <div className='col-11 col-lg-10 dashboard-box'>
                <div className='card mt-4 mb-4 pt-5 row border-white'>
                    <h4 className='col-12 ms-2'>{t('app.fac.title')}</h4>
                    <p className='col-12 fw-semibold ms-2'>{t('app.fac.subtitle')}
                    </p>

                    <div className='row px-3 gx-3 gy-3'>
                        <div className='col-2'>
                            <label className='form-label ms-2'>{t('app.fac.institution')}</label>
                            {institutions?.length > 0 && <select className='form-select' id="institution" onChange={() => changeFilter()}>
                                <option value='' selected>{t('app.fac.selectInstitution')}</option>
                                {institutions?.map(institution => (
                                    <option key={institution.id} value={institution.id} selected={institutionId == institution.id}>{institution.erakundea}</option>
                                ))}
                            </select>}
                        </div>
                        <div className='col-2'>
                            <label className='form-label ms-2'>{t('app.fac.status')}</label>
                            <select className='form-select' id="status" onChange={() => changeFilter()}>
                                <option value='' selected>{t('app.fac.selectStatus')}</option>
                                <option value='1'>{t('app.fac.correct')}</option>
                                <option value='0'>{t('app.fac.incorrect')}</option>
                            </select>
                        </div>
                        <div className='col-2'>
                            <label className='form-label ms-2'>{t('app.fac.startDate')}</label>
                            <input type='date' className='form-control' id="start" onChange={() => changeFilter()}/>
                        </div>
                        <div className='col-2'>
                            <label className='form-label ms-2'>{t('app.fac.endDate')}</label>
                            <input type='date' className='form-control' id="end" onChange={() => changeFilter()}/>
                        </div>
                        <div className='col-2'></div>
                        <div className='col-2 d-flex align-items-end justify-content-end'>   
                            <button className='btn button-landing effect btn-terciary' style={{height: '38px', width: '100%'}} onClick={() => cleanFilters()}>{t('app.fac.cleanFilters')}</button>
                        </div>
                    </div>
                    
                    <div className='px-3'>
                        {loading ? (
                            <div className="loader" style={{ width: '35px', margin: '2rem auto', textAlign: 'center', padding: '6px' }}></div>
                        ) : (
                            <div>
                                {facs.length > 0 ? (
                                <div className={`table-box mt-5 mb-2 p-0 ${loading ? '' : 'loaded'}`}>
                                        <table className='table table-bordered dashboard-table'>
                                            <thead>
                                                <tr className='table-secondary table-borderless border-white'>
                                                    <th scope='col'>{t('app.fac.institution')}</th>
                                                    <th scope='col'>{t('app.fac.group')}</th>
                                                    <th scope='col'>{t('app.fac.status')}</th>
                                                    <th scope='col'>{t('app.fac.date')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.map(fac => (
                                                    <tr key={fac.idinstitucion}>
                                                        <td>{fac.institution_name}</td>
                                                        <td>{fac.group}</td>
                                                        <td>{fac.status}</td>
                                                        <td>{fac.date}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    
                                </div>
                                ) : (
                                    <p className='text-center mt-5'>{t('app.fac.norecords')}</p>
                                )}

                                {facs.length > 0 && <div className="pagination-controls d-flex align-items-center">
                                    <span className="page-link-n" onClick={prevPage} disabled={currentPage === 1}>
                                        &#60; {t('app.fac.prev')}
                                    </span>
                                    <p className="mt-3 text-center flex-grow-1">{t('app.fac.page')} {currentPage} {t('app.fac.of')} {Math.ceil(facs.length / itemsPerPage)} ({t('app.fac.total')}: {countFacs} {t('app.fac.reports')})</p>
                                    <span className="page-link-n" onClick={nextPage} disabled={currentPage === Math.ceil(facs.length / itemsPerPage)}>
                                        {t('app.fac.next')} &#62;
                                    </span>
                                </div>}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminPage