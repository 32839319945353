import React, {useState, useEffect, useRef} from 'react'
import ApiService from '../../service/ApiService';
import { useTranslation } from 'react-i18next';

const PDFForm = ({institution, navigate}) => { 

    const apiService = new ApiService();

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [logoUrl, setLogoUrl] = useState(institution.logo ? institution.logo : 'https://placehold.co/300x200');
    const form = useRef(null);
    const logoRef = useRef(institution.logo);
    const pdfTextRef = useRef("Texto de prueba");
    const { t } = useTranslation();

    const handleFocusLabel = (e) => {
        const label = e.target.previousElementSibling;
        label.classList.add('active');
    }

    const handleBlurLabel = (e) => {
        const label = e.target.previousElementSibling;
        if (e.target.value === '') {
            label.classList.remove('active');
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setError(false);
        setSuccess(false);
        const formData = new FormData();
        formData.append('logo', logoRef.current.files[0]);
        formData.append('pdfText', pdfTextRef.current.value);
        apiService.post('updatePdf.php', formData)
        .then(response => response.json())
        .then(data => {
            if (data.error) {
                setError(true);
                setErrorMessage(data.error);
            } else {
                setSuccess(true);
            }
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error:', error);
            setError(true);
            setErrorMessage(t('app.customPDF.error'));
            setLoading(false);
        });
    }

  return (
    <form className='row landing-form px-0 mx-0 mt-4' ref={form} onSubmit={handleSubmit}>
        
        <div className='input-group col-6 mb-3'>
            <label className='active z-5'>{t('app.customPDF.logo')}</label>
            <input ref={logoRef} type='file' className='form-control mt-2' placeholder='Introduzca el logo' />
        </div>
        <div className='col-6 mb-3'>
            <img src={logoUrl} alt='logo institution' className='logo-img' />
        </div>
        <div className='input-group col-6 mb-3'>
            <label class="active">{t('app.customPDF.textPDF')}</label>
            <textarea ref={pdfTextRef} className='form-control mt-0' placeholder='Introduzca el texto del pdf' onFocus={handleFocusLabel} onBlur={handleBlurLabel}></textarea>
        </div>
        
        <div className='input-group col-12 mt-2'>
            <button className='btn button-landing effect effect-1 btn-terciary' type='submit'>
            {t('app.customPDF.save')} {loading && <div className="loader loader-form"></div>}
            </button>
        </div>
        {error && <p className="patia-error mt-2">{errorMessage}</p>}
        {success && <p className="patia-success mt-2">{t('app.customPDF.success')}</p>}
    </form>
  )
}

export default PDFForm