// Login.js
import React, { useState, useEffect } from 'react';
import ApiService from '../../service/ApiService';
import '../Login/Login.css';
import { ReactComponent as IconSvg } from '../../svg/PatiaIcon.svg'
import { ReactComponent as LogoSvg } from '../../svg/PatiaLogo.svg'

function Register({ checkUserSession, getStatus, navigate }) {
  const [username, setUsername] = useState('');
  const [verify, setVerify] = useState(false);
  const apiService = new ApiService();

  useEffect(() => {
    if(!verify){
      checkUserSession().then(userInfo => {
        console.log('user',userInfo);
        if (userInfo) {
          navigate('#welcome');
        }
      });
      setVerify(true);
    }
  },[setVerify, verify, checkUserSession, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
  
    try {

      const body = `username=${encodeURIComponent(username)}`;
      const options = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
  
      apiService.post('register.php', body, options)
      .then(response => {
        if (!response) throw new Error('Not authorized');
        // muestro un alert de que se ha registrado correctamente y debe esperar por un email de confirmacion
        //console.log("login hecho",response);
        alert("Se ha registrado correctamente, debe esperar por un email de confirmacion");
      })
      .catch(error => {
        console.error('Login error', error);
      });
    } catch (error) {
      console.error("Login error", error);
    }
  };

  return (
    <div className="patia-container-background">
      <IconSvg className="patia-icon" />
      <div className="patia-login-container">
        <div className="patia-login-background"> 
          <div className="patia-logo-container">
            <LogoSvg className="patia-logo" />
          </div>
        </div>
        <div className="patia-login-form-container">
            <p href='/#password-recovery' className="patia-need-help"></p>
            <form className='patia-form patia-form-user' onSubmit={handleLogin}>
            <div className='patia-form-container'>
                <h2>Register</h2>
                <div className='d-flex flex-column mb-3 patia-form-input'>
                  <input type="text" name="email" value={username} onChange={(e) => setUsername(e.target.value)} required minLength={1}/>
                  <label htmlFor="email">User</label>
                </div>
                
                <button type="submit">Registro</button>
                <a href='/#register' className="patia-form-subtxt mt-3" onClick={() => navigate('#login')}>Tienes cuenta?</a>
                <a href='/#register' className="btn btn-secondary btn-subtxt" onClick={() => navigate('#login')}>Log in</a>
            </div>
            </form>
        </div>
      </div>
    </div>
  );
}

export default Register;